<script setup>

import {useForm} from "@inertiajs/vue3";
import {defineEmits, ref, watch} from "vue";

const form = useForm({
  name: null
})

// Deklarujemy emitowane zdarzenia
const emit = defineEmits(['categoryAdded']);

// Zamykamy modal po pomyślnym dodaniu kategorii
watch(() => form.processing, (isProcessing) => {
  if (!isProcessing && form.wasSuccessful) {
    form.name = null;
    document.getElementById('closeBtn').click();
    emit('categoryAdded', form.data());
  }
});
</script>

<template>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#createCategoryModal">
    Utwórz kategorię
  </button>

  <div class="ms-auto">
  <Link class="btn btn-outline-primary btn-sm ms-2" :href="route('projects.default-suppliers.index')">Domyślni dostawcy
  </Link>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="createCategoryModal" tabindex="-1" role="dialog"
       aria-labelledby="modelTitleId"
       aria-hidden="true">
    <div class="modal-dialog" role="document">
      <form class="modal-content" @submit.prevent="form.post(route('customers-categories.store'))">
        <div class="modal-header">
          <h4 class="modal-title" id="modelTitleId">Dodaj kategorię</h4>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="input-group align-items-center">
            <label for="categoryName">Nazwa Kategorii:&nbsp;</label>
            <input type="text" v-model="form.name" id="categoryName" class="form-control form-control-sm" required>
          </div>
          <div v-if="form.errors.name" class="text-danger">{{ form.errors.name }}</div>
        </div>
        <div class="modal-footer">
          <button id="closeBtn" type="button" class="btn btn-secondary me-auto" data-bs-dismiss="modal">Zamknij</button>
          <button type="submit" class="btn btn-primary" :disabled="form.processing">Zapisz</button>
        </div>
      </form>
    </div>
  </div>

</template>