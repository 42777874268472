<template>

  <!-- Button trigger modal -->
  <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal"
          data-bs-target="#historyOrderListModal" @click="refreshData()">
    Zamówienia historyczne
  </button>

  <!-- Modal -->
  <div class="modal fade" id="historyOrderListModal" tabindex="-1" role="dialog" aria-labelledby="historyOrderListModal"
       aria-hidden="true">
    <div class="modal-dialog modal-full-width" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Zamówienia historyczne</h5>
          <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="widget-content">
            <div>
              <ejs-grid ref="historyOrderListGrid" id="historyOrderListGrid" height="500px"
                        :dataSource="orderListDataManager"
                        :allowTextWrap='true'
                        :allowExcelExport='false'
                        :allowPaging="true"
                        :allowSorting='true'
                        :allowFiltering='true'
                        :allowReordering='true'
                        :allowResizing='true'
                        :showColumnChooser='true'
                        :allowSelection='true'
                        :enablePersistence='false'
                        :allowGrouping='true'
                        :contextMenuItems="contextMenuItems"
                        :contextMenuClick="clickContextMenuHandler"
                        :pageSettings="pageSettings"
                        :editSettings="editSettings"
                        :filterSettings="filterOptions"
                        :toolbar="toolbar">
                <e-columns>
                  <e-column width="5" field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                            :visible="false" :filter="filterDefault"></e-column>
                  <e-column field="order_list.order_number" headerText="Numer zamówienia" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="quote_request_item.quote_request_number" headerText="Zapytanie ofertowe"
                            :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="project.number" headerText="Projekt" :allowEditing="false"
                            :visible="true"></e-column>
                  <e-column field="team_name" headerText="Zespół" :allowEditing="false" :visible="true"></e-column>
                  <e-column field="article.id" headerText="ID artykułu" :allowEditing="false"
                            :visible="false"></e-column>
                  <e-column field="article.catalog_number" headerText="Nr artykułu" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="article.model" headerText="Model artykułu" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="supplier.short_name" headerText="Wybrany dostawca" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="send_to_mails" headerText="Maile" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="send_to_mails_dw" headerText="Maile DW" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="sent_date" headerText="Data wysłania maili" :allowEditing="false"
                            :filter="filterDefault" type="date" editType="datepickeredit"
                            format="dd.MM.yyyy"></e-column>
                  <e-column field="delivery_date_deadline" headerText="Ostateczny termin dostawy" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="quantity_required" headerText="Wymagana ilość" :allowEditing="true"
                            :filter="filterDefault"></e-column>
                  <e-column field="delivered_quantity" headerText="Dostarczona ilość" :allowEditing="true"
                            :filter="filterDefault" editType="numericedit" format="N2"></e-column>
                  <e-column field="dispatched_quantity" headerText="Wydana ilość" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="price_netto" headerText="Cena netto" :allowEditing="true"
                            :filter="filterDefault" editType="numericedit" format="N2"></e-column>
                  <e-column field="vat" headerText="VAT" :allowEditing="true"
                            :filter="filterDefault" editType="numericedit" format="N0"></e-column>
                  <e-column field="currency" headerText="Waluta" :allowEditing="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="status.id" headerText="Status" :allowEditing="true" editType="dropdownedit"
                            foreignKeyValue='name' foreignKeyField='id' :dataSource="order_list_statuses"
                            :filter="filterStatuses"></e-column>
                  <e-column field="comments" headerText="Komentarz" :allowEditing="true" :allowFiltering="false"
                            :filter="filterDefault"></e-column>
                  <e-column field="created_at" headerText="Data utworzenia" :allowEditing="false" format="dd.MM.yyyy"
                            :filter="filterDefault"></e-column>
                  <e-column field="created_by_name" headerText="Utworzone przez" :allowEditing="false"
                            :filter="filterDefault"></e-column>

                  <template v-slot:deliverersShortNamesTemplate="{data}">
                    {{ formatDeliverers(data) }}
                  </template>
                </e-columns>

              </ejs-grid>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ContextMenu,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  Group,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import AppLayout from "../../Layouts/App.vue";
import {MultiSelect} from "@syncfusion/ej2-vue-dropdowns";
import {usePage} from "@inertiajs/vue3";

let statusFilter;
export default {
  components: {
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  emits: ['restore-success', 'restore-error', 'refresh-grid'],

  props: {
    order_list_statuses: Object
  },

  data() {
    return {
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains'},
      isRestoring: false,
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      contextMenuItems: this.getContextMenu(),
      orderListDataManager: {},

      filterStatuses: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            statusFilter = new DropDownList({
              dataSource: this.order_list_statuses,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj Statusu',
              popupHeight: '200px',
              allowFiltering: true
            });
            statusFilter.appendTo(flValInput);
          },
          write: (args) => {
            statusFilter.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, statusFilter.text);
          }
        }
      },
    }
  },

  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, Group, ForeignKey, ContextMenu]
  },

  methods: {
    getData() {
      return new DataManager({
        url: route('historyOrderLists.getList', {project: this.selectedProjectId, team: this.selectedTeamName}),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('orderLists.saveChanges'),
        updateUrl: route('orderLists.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    refreshData() {
      this.orderListDataManager = this.getData();
    },

    getContextMenu() {
      return [
        {text: 'Przywróć zamówienie', target: '.e-content', id: 'restore_order'},
      ]
    },

    clickContextMenuHandler(args) {
      if (args.item.id === 'restore_order') {
        Toast.fire({
          toast: false,
          position: 'center',
          icon: 'question',
          title: 'Cofnięcie zamówienia',
          html: 'Czy na pewno chcesz cofnąć status zamówienia?',
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          showConfirmButton: true,
          confirmButtonText: 'Tak',
          confirmButtonColor: 'success',
          showCancelButton: true,
          cancelButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {

            this.$nextTick(() => {
              axios.post(route('historyOrderLists.item.restore', {
                    rowToRestore: args.rowInfo.rowData
                  })
              ).then(response => {
                if (response.status === 200) {
                  this.$emit('restore-success', 'Zamówienie cofnięte.');
                  this.$emit('refresh-grid', true);
                  this.$refs.historyOrderListGrid.refresh();

                } else {
                  this.$emit('restore-error', 'Coś poszło nie tak: ' + response.data);
                  console.log(response);
                }
              }).catch(error => {
                this.$emit('restore-error', 'Coś poszło nie tak: ' + error.response.data);
                console.log(error);
              });
            })

          }
        });
      }
    }
  }
}

</script>