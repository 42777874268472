<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading">
          <div class="mb-3">
            <Link :href="route('users.index')" class="btn btn-outline-primary">
              <i class="bi bi-plus-circle"></i> &nbsp; Lista użytkowników
            </Link>
          </div>

        </div>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="row">
            <div class="col-md-4 col-sm-12">
              <h3 class="card-header">Użytkownik</h3>
              <div class="card-body">
                <form @submit.prevent="form.put(route('users.update', form.id))">
                  <label class="form-label" for="first_name">Imię</label>
                  <div v-if="form.errors.first_name">{{ form.errors.first_name }}</div>
                  <input class="form-control form-control-sm" id="name" v-model="form.first_name"/>

                  <label class="form-label" for="last_name">Nazwisko:</label>
                  <div v-if="form.errors.last_name">{{ form.errors.last_name }}</div>
                  <input class="form-control form-control-sm" id="last_name" v-model="form.last_name"/>

                  <label class="form-label" for="position">Stanowisko:</label>
                  <div v-if="form.errors.position">{{ form.errors.position }}</div>
                  <input class="form-control form-control-sm" id="position" v-model="form.position"/>

                  <label class="form-label" for="department_id">Dział:</label>
                  <div v-if="form.errors.department_id">{{ form.errors.department_id }}</div>
                  <select class="form-select form-select-sm" id="department" v-model="form.department_id">
                    <option value="">Wybierz dział</option>
                    <option v-for="department in departments" :key="department.id" :value="department.id">
                      {{ department.name }}
                    </option>
                  </select>

                  <label class="form-label" for="short_name">Email</label>
                  <div v-if="form.errors.short_name">{{ form.errors.short_name }}</div>
                  <input class="form-control form-control-sm" id="email" v-model="form.email"/>

                  <label class="form-label" for="phone">Nr. tel</label>
                  <div v-if="form.errors.phone">{{ form.errors.phone }}</div>
                  <input class="form-control form-control-sm" id="phone" v-model="form.phone"/>

                  <label class="form-label" for="password">Hasło:</label>
                  <div v-if="form.errors.password">{{ form.errors.password }}</div>
                  <input class="form-control form-control-sm" type="password" id="password" v-model="form.password"/>

                  <label class="form-label" for="password_confirmation">Powtórz hasło:</label>
                  <div v-if="form.errors.password_confirmation">{{ form.errors.password_confirmation }}
                  </div>
                  <input class="form-control form-control-sm" type="password" id="password_confirmation"
                         v-model="form.password_confirmation"/>

                  <label class="form-label" for="roles">Role:</label>
                  <div v-if="form.errors.role">{{ form.errors.role }}</div>
                  <multiselect
                      id="roles"
                      open-direction="bottom"
                      v-model="form.roles"
                      tag-placeholder="Dodaj jako nowy"
                      placeholder="Szukaj"
                      label="name"
                      track-by="name"
                      :options="options"
                      :multiple="true"
                      :taggable="true"
                      @tag="addTag">
                  </multiselect>

                  <div class="mt-3" v-if="form.isDirty">Istnieją nie zapisane dane w formularzu.</div>
                  <button class="btn btn-primary mt-3 m-2" type="submit" :disabled="form.processing">Zapisz</button>

                  <button class="float-end btn btn-danger mt-3 m-2" @click="deactivateUser" :disabled="form.processing"
                          v-show="currentUser.is_active">
                    Dezaktywuj Użytkownika
                  </button>

                  <button class="float-end btn btn-success mt-3 m-2" @click="activateUser" :disabled="form.processing"
                          v-show="!currentUser.is_active">
                    Aktywuj Użytkownika
                  </button>
                </form>
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <h3 class="card-header">Uprawnienia niestandardowe</h3>
              <div class="card-body mt-4">
                <table>
                  <tr>
                    <td>
                      <h5>Klienci</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Dostęp do klientów</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canViewClients"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>


                  <tr>
                    <td class="pt-5">
                      <h5>Oferty</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Dostęp do ofert</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canViewOffers"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Rozszerzony widok ofert</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canSeeExtendedOfferView"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Dostęp do tworzenia/edytowania ofert</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input type="checkbox"
                               v-model="permissions.canEditOffers"
                               @change="updatePermissions(this)">
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Dostęp do edytowania szablonów ofert</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input type="checkbox"
                               v-model="permissions.canEditOffersTemplates"
                               @change="updatePermissions(this)">
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>


                  <tr>
                    <td class="pt-5">
                      <h5>Projekty</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">
                        Podstawowa edycja projektów<br>
                        <small>(Utwórz projekt, edycja nazwy, karta otwarcia zlecenia, zmiana priorytetów)</small>
                      </label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canEditBasicProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">
                        Zatwierdzanie projektów<br>
                        <small>(Możliwość zatwierdzania w projektach)</small>
                      </label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canApproveProjects"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">
                        Rozszerzona edycja projektów<br>
                        <small>(projekt, kontakty projektu, wybrani dostawcy, ocena ryzyka, numeracja)</small>

                      </label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canEditAdvancedProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Archiwizowanie / przywracanie projektów</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canArchiveRestoreProjects"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Edycja spędzony czas, budżet/koszty projektów</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canEditTimeSpentAndBudgetProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Dostęp do płatności + status płatności projektów</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canViewPaymentsProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Edycja płatności + status płatności projektów</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canEditPaymentsProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Dostęp do gwarancji projektów</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canViewWarrantyProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Widok harmonogramu</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canViewScheduleProject"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Edycja harmonogramu</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canEditResourceSchedule"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col-md-4 col-sm-12">
              <div class="card-body mt-4">
                <table>
                  <tr>
                    <td>
                      <h5>Magazyn</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label class="mt-3 me-2">Widoczność cen artykułów / historii</label>
                    </td>
                    <td>
                      <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                        <input
                            type="checkbox"
                            v-model="permissions.canViewArticlesPricesAndHistory"
                            @change="updatePermissions(this)"
                        >
                        <span class="slider round"></span> </label>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>

import {Link, useForm} from "@inertiajs/vue3";
import AppLayout from "@/Layouts/App.vue";
import Multiselect from '@suadelabs/vue3-multiselect';
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";

export default {
  components: {AppLayout, Link, Multiselect},
  props: {
    currentUser: Object,
    roles: Array,
    departments: Object
  },
  setup(props) {
    let data = {
      id: props.currentUser.id,
      first_name: props.currentUser.first_name,
      last_name: props.currentUser.last_name,
      position: props.currentUser.position,
      department_id: props.currentUser.department_id || '',
      email: props.currentUser.email,
      phone: props.currentUser.phone,
      password: null,
      password_confirmation: null,
      roles: props.currentUser.roles
    };
    const form = useForm(data)

    return {form, departments: props.departments}
  },
  data() {
    return {
      options: this.roles,
      permissions: Object,
    };
  },
  mounted() {
    this.permissions = this.$page.props.permissions;

    // trzeba ustawić jakąkolwiek wartość, bo inaczej, jak nie ma uprawnień to się nie zapisują
    if (this.permissions.length === 0) {
      this.permissions = {canSeeExtendedOfferView: false};
    }
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag.name,
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    async activateUser(event) {
      event.preventDefault();
      if (confirm("Czy na pewno chcesz aktywować tego użytkownika?")) {
        this.form.post(route('users.activate', this.form.id));
      }
    },

    async deactivateUser(event) {
      event.preventDefault();
      if (confirm("Czy na pewno chcesz zdezaktywować tego użytkownika?")) {
        this.form.post(route('users.deactivate', this.form.id));
      }
    },

    updatePermissions() {
      axios.post(route('user.updatePermissions', {'user': this.currentUser.id}), {
        permissions: this.permissions
      })
          .then(response => {
            Toast.fire({
              position: 'top-end',
              toast: true,
              icon: 'success',
              title: 'Sukces',
              html: 'Poprawnie zapisano nowe uprawnienia',
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
              showCloseButton: true
            });
          })
          .catch(error => {
            console.log(error.response.data.message);
            Toast.fire({
              position: 'top-end',
              toast: true,
              icon: 'error',
              title: 'Błąd',
              html: error.response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
              timerProgressBar: true,
              showConfirmButton: false,
              showCloseButton: true
            });
          });
    }


  }
}
</script>
