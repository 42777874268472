<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Zapotrzebowanie projektowe</h4>
              <div class="ms-auto">
                <div class="form-check form-switch form-switch-success">
                  <input class="form-check-input" type="checkbox" role="switch" id="showArchivedProjects"
                         style="height:1.5em; width:3em;"
                         v-model="archivedShown" @change="changeShowArchived()">
                  <label class="form-check-label" for="showArchivedProjects" style="margin: 3px 0 0 10px;">Pokaż
                    zarchiwizowane</label>
                </div>
              </div>
            </div>
            <div class="mt-3 row">

              <div class="col-md-12 col-lg-6">
                <label for="project-select">Wybierz projekt:</label>
                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"
                        v-model="selectedProjectId">
                  <option value="0" selected>Wszystkie projekty</option>
                  <option v-for="project in projects" :key="project.id" :value="project.id">
                    {{ project.label }}
                  </option>
                </select>
              </div>

              <div class="col-md-12 col-lg-6">
                <label for="team-select">Wybierz zespół:</label>
                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"
                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">
                  <option value="" disabled>Wybierz zespół</option>
                  <option v-for="team in teams" :key="team.name" :value="team.name">
                    {{ team.name }}
                  </option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="requiredProjectItemsGrid" id="requiredProjectItemsGrid" height="500px"
                      :dataSource="requiredProjectItemsDataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :selectionSettings="selectionOptions"
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionComplete="actionCompleteHandler"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column type="number" width="70" :template="'indexTemplate'"
                          :allowEditing="false" :allowSorting="false" :allowFiltering="false"></e-column>
                <e-column type="checkbox" width="100"></e-column>
                <e-column field="id" headerText="Nr poz." width="125" :isPrimaryKey="true" :allowEditing="false"
                ></e-column>
                <e-column field="team_name" headerText="Zespół" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="subteam_drawing_no" headerText="Podzespół nr rysunku" :allowEditing="true"
                          :filter="filterDefault"></e-column>
                <e-column field="article.catalog_number" headerText="Nr katalogowy" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.model" headerText="Model" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.description" headerText="Nazwa/Opis" width="200"
                          :allowEditing="false" :filter="filterDefault"></e-column>
                <e-column field="article.link" headerText="Link" :filter="filterDefault" :allowEditing="false"
                          :template="'linkTemplate'"></e-column>
                <e-column field="article.article_unit.name" headerText="J.m." width="105"
                          :allowEditing="false" :dataSource="units" :filter="filterUnits"></e-column>
                <e-column field="article.producer.id" headerText="Producent" foreignKeyValue='short_name'
                          foreignKeyField='id' :dataSource="producers" :filter='filterProducers'
                          :allowEditing="false"></e-column>
                <e-column field="article.customer_category.id" foreignKeyValue='name' foreignKeyField='id'
                          :dataSource="categories" :filter='filterCategories' headerText="Kategoria"
                          :allowEditing="false"></e-column>
                <e-column field="quantity_required" headerText="Ilość wymagana" format="N" type="number"
                          :allowEditing="true"></e-column>
                <e-column field="suppliers_short_names" headerText="Dostawcy" :allowEditing="true" editType="string"
                          :filter='filterSuppliers' :edit="suppliersParams"></e-column>
                <e-column field="created_by.name" headerText="Dodany przez" :allowEditing="false"></e-column>
                <e-column field="created_at" headerText="Data dodania" :allowEditing="false" type="date"
                          format="dd.MM.yyyy H:mm:ss"></e-column>
                <e-column field="status" headerText="Status" :allowEditing="true" editType="dropdownedit"
                          :edit="requiredProjectItemsStatusesParams"></e-column>
                <e-column field="delivery_date" headerText="Data dostawy" :allowEditing="true" type="date"
                          format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                <e-column field="release_date" headerText="Data zwolnienia" :allowEditing="true" type="date"
                          format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                <e-column field="order_submission_date" headerText="Data Zlec. Zam." :allowEditing="true" type="date"
                          format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                <e-column field="order_status_confirmation_date" headerText="Data Wydr. Zam." :allowEditing="true"
                          type="date" format="dd.MM.yyyy" editType="datepickeredit"></e-column>
                <e-column field="inventory_status" headerText="Status Mag." :allowEditing="true" editType="dropdownedit"
                          :edit="inventoryStatusesParams"></e-column>
                <e-column field="production_due_date" headerText="Data Prod." :allowEditing="true" type="date"
                          format="dd.MM.yyyy"></e-column>
                <e-column field="lead_time" headerText="Czas dostawy" :allowEditing="true" type="date"
                          format="dd.MM.yyyy"></e-column>
                <e-column field="comments" headerText="Uwagi" :allowEditing="true" type="string"></e-column>
                <e-column field="updated_by.name" headerText="Ostatnio zmienił" :allowEditing="false"></e-column>
                <e-column field="article_main_warehouse.quantity" headerText="Ilość magazynowa" format="N" type="number"
                          :allowEditing="false"></e-column>
                <e-column field="warehouse_received" headerText="Magazyn (odłożony)" :filter="filterDefault"></e-column>
                <e-column field="patent_number" headerText="Nr Patentu" :allowEditing="true"></e-column>
                <e-column field="none!!!!!!" headerText="Przekr. term. dost." :allowEditing="false"></e-column>
                <e-column :template="'quantityTemplate'" headerText="Ilość do zam." :allowEditing="false"></e-column>
                <e-column field="barcode" headerText="Kody kreskowe" :allowEditing="true"></e-column>
              </e-columns>

              <template v-slot:indexTemplate="{data}">
                <span style="color:#a6a6a6">{{ ++data.index }}</span>
              </template>

              <template v-slot:linkTemplate="{data}">
                <a class="link link-primary" target="_blank" rel="noopener noreferrer" :href=data.article.link>
                  {{ data.article.link }}</a>
              </template>

              <template v-slot:quantityTemplate="{ data }">
                {{ (data.numbering?.quantity || 0) * (data.quantity_required || 0) }}
              </template>

            </ejs-grid>
          </div>

        </div>
      </div>
    </div>

    <AddRequiredProjectItemsModal :project="selectedProjectId" :team="selectedTeamName"
                                  ref="addRequiredProjectItemsModalRef" :suppliers="suppliers"
                                  :units="units" @added-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                  @added-success="successMsg" @added-error="errorMsg"/>

    <EditArticleModal :article="selectedRecordToEdit" @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                      ref="editArticleModalRef" :units="units" :categories="categories" :producers="producers"
                      :warehouses="warehouses" :exchangeRates="exchangeRates" @update-success="successMsg"
                      @update-error="errorMsg"/>

    <AddToQuoteRequestModal :project="selectedProjectId" :selectedItems="selectedRecords"
                            ref="addQuoteRequestModalRef"
                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                            @added-success="successMsg" @added-error="errorMsg"/>

    <CloneRequestProjectItemModal :selectedProject="selectedProjectId" :selectedTeam="selectedTeamName"
                                  :selectedRecords="selectedRecords" ref="cloneRequestProjectItemModalRef"
                                  :teams="teams" :projects="projects"
                                  @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                  @added-success="successMsg" @added-error="errorMsg"/>

    <MoveItemToWarehouseModal ref="moveItemToWarehouseModal" :project="selectedProjectId" :team="selectedTeamName"
                              :selectedItem="selectedRecord" :checkWarehouses="false"
                              @update-grid="successMsg" @added-success="successMsg" @added-error="errorMsg"
                              @added-article="this.$refs.orderListGrid.refresh()"/>

    <ChangeStatusModal :selectedRecords="selectedRecords" ref="changeStatusModalRef" :statuses="statuses"
                       @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                       @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeStatusMagModal :selectedRecords="selectedRecords" ref="changeStatusMagModalRef"
                          :statuses="inventory_statuses"
                          @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                          @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeDeliveryDateModal :selectedRecords="selectedRecords" ref="changeDeliveryDateModalRef"
                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                            @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeReleaseDateModal :selectedRecords="selectedRecords" ref="changeReleaseDateModalRef"
                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                            @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeOrderSubmissionDateModal :selectedRecords="selectedRecords" ref="changeOrderSubmissionDateModalRef"
                                    @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                    @added-success="successMsg" @added-error="errorMsg"/>

    <ChangeOrderStatusConfirmationDateModal :selectedRecords="selectedRecords"
                                            ref="changeOrderStatusConfirmationDateModalRef"
                                            @updated-article="this.$refs.requiredProjectItemsGrid.refresh()"
                                            @added-success="successMsg" @added-error="errorMsg"/>

  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {usePage} from "@inertiajs/vue3";
import {MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  RowDD,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, Query, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import EditArticleModal from "./EditArticleModal.vue";
import {ref} from "vue";
import AddToQuoteRequestModal from "./AddToQuoteRequestModal.vue";
import AddRequiredProjectItemsModal from "./AddRequiredProjectItemsModal.vue";
import CloneRequestProjectItemModal from "./CloneRequestProjectItemModal.vue";
import ChangeStatusModal from "./ChangeStatusModal.vue";
import ChangeStatusMagModal from "./ChangeStatusMagModal.vue";
import ChangeDeliveryDateModal from "./ChangeDeliveryDateModal.vue";
import ChangeReleaseDateModal from "./ChangeReleaseDateModal.vue";
import ChangeOrderSubmissionDateModal from "./ChangeOrderSubmissionDateModal.vue";
import ChangeOrderStatusConfirmationDateModal from "./ChangeOrderStatusConfirmationDateModal.vue";
import MoveItemToWarehouseModal from "../OrderLists/MoveItemToWarehouseModal.vue";


let dropInstanceFilterUnits, dropInstanceFilterSuppliers, statusElem, statusObj, delivererElem, multiSelectObj;
export default {

  components: {
    MoveItemToWarehouseModal,
    ChangeStatusMagModal,
    ChangeDeliveryDateModal,
    ChangeReleaseDateModal,
    ChangeOrderSubmissionDateModal,
    CloneRequestProjectItemModal,
    ChangeOrderStatusConfirmationDateModal,
    ChangeStatusModal,
    AddRequiredProjectItemsModal,
    AddToQuoteRequestModal,
    EditArticleModal,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, RowDD, VirtualScroll]
  },

  props: {
    projects: Object,
    required_project_items_statuses: Object,
    inventory_statuses: Object,
    units: Object,
    suppliers: Object,
    statuses: Object,
    categories: Object,
    producers: Object,
    warehouses: Object,
    exchangeRates: Object
  },

  beforeMount() {
    // Odczytanie danych z sesji
    const storedProjectId = sessionStorage.getItem('selectedProjectId');
    const storedTeam = sessionStorage.getItem('selectedTeam');
    this.archivedShown = sessionStorage.getItem('archivedShown');

    if (storedProjectId) {
      this.selectedProjectId = storedProjectId === 'null' ? 0 : storedProjectId;
      this.updateTeams();
    }

    this.selectedTeamName = storedTeam;
    this.getRequiredProjectItemsForProject();
  },

  data() {
    return {
      archivedShown: false,
      teams: [],
      requiredProjectItemsDataManager: [],
      fetchedOrders: [],
      selectedProjectId: null,
      selectedTeamName: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedRecordToEdit: null,
      selectedRecords: null,
      selectedRecord: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains'},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {checkboxOnly: true},
      contextMenuItems: this.getContextMenu(),

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      suppliersParams: {
        create: () => {
          delivererElem = document.createElement('input');
          return delivererElem;
        },
        read: () => {
          return multiSelectObj.value.join(",");
        },
        destroy: () => {
          multiSelectObj.destroy();
        },
        write: (args) => {
          const selectedValues = args.rowData.suppliers_short_names ? args.rowData.suppliers_short_names.split(",") : [];

          // Inicjalizacja MultiSelect
          multiSelectObj = new MultiSelect({
            value: selectedValues,
            dataSource: this.suppliers,
            fields: {text: "short_name", value: "short_name"},
            placeholder: 'Wybierz dostawców',
            mode: 'Box',
            allowFiltering: true,
            popupHeight: '200px',
            closePopupOnSelect: false,
          });

          multiSelectObj.appendTo(delivererElem);
        }
      },

      requiredProjectItemsStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.required_project_items_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Niestandardowa akcja przy dodawaniu
       */
      if (args.item.text === 'Dodaj') {
        args.cancel = true;

        if ((this.selectedProjectId && this.selectedProjectId !== '0') && (this.selectedTeamName && this.selectedTeamName !== 'null')) {
          await this.$nextTick(() => {
            this.$refs.addRequiredProjectItemsModalRef.show();
          });
        } else
          this.errorMsg('Najpierw wybierz projekt i zespół.');
      }

      /**
       * Akcja przy usuwaniu artykułu.
       */
      if (args.item.text === 'Usuń') {
        const selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        if (selectedRecords.length > 0) {
          selectedRecords.forEach((record) => {
            this.$refs.requiredProjectItemsGrid.deleteRecord("id", record);
          });
        }
      }

      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.requiredProjectItemsGrid.clearFiltering();
      }
    },

    getContextMenu() {
      return [
        {text: 'Edytuj artykuł', target: '.e-content', id: 'edit_article'},
        {separator: true},
        // {text: 'Sprawdź poprzednie zapytania tego artykułu', target: '.e-content', id: 'check_quote_request'},
        {text: 'Utwórz zapytanie ofertowe', target: '.e-content', id: 'create_quote_request'},
        {text: 'Kopiuj do innego projektu/zespołu', target: '.e-content', id: 'clone_to_other_project'},
        {text: 'Dyspozycja magazynowa', target: '.e-content', id: 'warehouse_disposition'},
        {text: 'Zmień status', target: '.e-content', id: 'change_status'},
        {text: 'Zmień status magazynowy', target: '.e-content', id: 'change_status_mag'},
        {text: 'Zmień datę dostawy', target: '.e-content', id: 'change_delivery_date'},
        {text: 'Zmień datę zwolnienia', target: '.e-content', id: 'change_release_date'},
        {text: 'Zmień datę zlecenia zamówienia', target: '.e-content', id: 'change_order_submission_date'},
        {text: 'Zmień datę wydruku zamówienia', target: '.e-content', id: 'change_order_status_confirmation_date'},
      ];
    },

    clickContextMenuHandler(args) {
      if (args.item.id === 'edit_article') {
        this.selectedRecordToEdit = args.rowInfo.rowData.article;
        this.$nextTick(() => {
          this.$refs.editArticleModalRef.show();
        });
      }

      if (args.item.id === 'create_quote_request') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.addQuoteRequestModalRef.show();
        });
      }

      if (args.item.id === 'clone_to_other_project') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.cloneRequestProjectItemModalRef.show();
        })
      }

      if (args.item.id === 'warehouse_disposition') {
        this.selectedRecord = args.rowInfo.rowData;
        this.$refs.moveItemToWarehouseModal.show();
      }

      if (args.item.id === 'change_status') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeStatusModalRef.show();
        })
      }

      if (args.item.id === 'change_status_mag') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeStatusMagModalRef.show();
        })
      }

      if (args.item.id === 'change_delivery_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeDeliveryDateModalRef.show();
        })
      }

      if (args.item.id === 'change_release_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeReleaseDateModalRef.show();
        })
      }

      if (args.item.id === 'change_order_submission_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeOrderSubmissionDateModalRef.show();
        })
      }

      if (args.item.id === 'change_order_status_confirmation_date') {
        this.selectedRecords = this.$refs.requiredProjectItemsGrid.getSelectedRecords();
        this.$nextTick(() => {
          this.$refs.changeOrderStatusConfirmationDateModalRef.show();
        })
      }
      // if (args.item.id === 'check_quote_request') {
      //
      //   window.open(route('quoteRequests.index', {
      //     requiredProjectItems: args.rowInfo.rowData.id,
      //     project: this.selectedProjectId,
      //     team: this.selectedTeamName,
      //     article: args.rowInfo.rowData.article.id
      //   }), '_blank');
      // }
    },

    updateSelectedProject(event) {
      this.selectedProjectId = Number(event.target.value);
      this.teams = null;
      this.selectedTeamName = null;
      if (this.selectedProjectId) {
        this.updateTeams()
      }

      this.getRequiredProjectItemsForProject();
    },

    updateTeams() {
      if (this.selectedProjectId) {
        const project = this.projects.find(project => project.id === Number(this.selectedProjectId));
        if (project) {
          this.teams = project.teams;
          this.selectedTeamName = null;
        }
      } else {
        this.teams = [];
        this.selectedTeamName = null;
      }
    },

    updateSelectedTeam(event) {
      this.selectedTeamName = event.target.value;
      this.getRequiredProjectItemsForProject();
    },

    getRequiredProjectItemsForProject() {
      sessionStorage.setItem('selectedProjectId', this.selectedProjectId);
      sessionStorage.setItem('selectedTeam', this.selectedTeamName);
      sessionStorage.setItem('archivedShown', this.archivedShown);

      this.requiredProjectItemsDataManager = new DataManager({
        url: route('requiredProjectItems.getList', {
          project: this.selectedProjectId,
          team: this.selectedTeamName
        }),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('requiredProjectItems.saveChanges'),
        updateUrl: route('requiredProjectItems.updateChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token},
          {'archivedShown': sessionStorage.getItem('archivedShown') || false}
        ],
      });
    },

    changeShowArchived() {
      sessionStorage.setItem('archivedShown', this.archivedShown ?? false);
      if (this.$refs.requiredProjectItemsGrid) {
        const query = new Query().addParams('archivedShown', this.archivedShown);
        document.getElementById('requiredProjectItemsGrid').ej2_instances[0].query = query;
      }
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // aktualizuj
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();

        }
      }
    },

    actionCompleteHandler(args) {
      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>