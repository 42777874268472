<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="addQuoteRequestModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Dodaj zamówienie</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="form" class="container-fluid">
              <template v-for="(article, idx) in form.articles" :key="article.id">
                <table class="table order-list-table">
                  <tbody>
                  <tr>
                    <td colspan="2">#{{ idx + 1 }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Do zapytania ofertowego</td>
                    <td>{{ article.quote_request?.request_number || '' }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Nr katalogowy</td>
                    <td>{{ article.catalog_number }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Model</td>
                    <td>{{ article.model }}</td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Potrzebna ilość</td>
                    <td>
                      <div v-if="hasError(`articles.${idx}.quantity_required`)" class="text-danger small">
                        {{ getErrorMessage(`articles.${idx}.quantity_required`) }}
                      </div>
                      <input class="form-control form-control-sm" type="number" step="0.1" min="0"
                             v-model="article.quantity_required" required>
                    </td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Producent</td>
                    <td>
                      <template v-if="article.producer_short_name">
                        <span class="font-weight-bolder">{{ article.producer_short_name }}</span>
                      </template>
                      <template v-else>
                        <i>Brak domyślnego producenta</i>
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Dostawca</td>
                    <td>
                      <div v-if="hasError(`articles.${idx}.supplier`)" class="text-danger small">
                        {{ getErrorMessage(`articles.${idx}.supplier`) }}
                      </div>
                      <template v-if="article.supplier">
                        <span class="font-weight-bolder">{{ article.supplier.short_name || '' }}</span>
                      </template>
                      <template v-else>
                        <i>Brak domyślnego dostawcy</i>
                      </template>
                      <div class="row">
                        <div v-if="hasError(`articles.${idx}.emails`)" class="text-danger small">
                          {{ getErrorMessage(`articles.${idx}.emails`) }}
                        </div>
                        <div class="col-md-6">
                          <span class="d-inline-block">Do: </span>
                          <input class="form-control form-control-sm d-inline-block"
                                 type="text"
                                 placeholder="Maile dostawcy"
                                 @keyup="checkEmails"
                                 v-model="article.emails.to"
                          />
                        </div>
                        <div class="col-md-6">
                          <span class="d-inline-block">DW: </span>
                          <input class="form-control form-control-sm"
                                 type="text"
                                 placeholder="Maile DW dostawcy"
                                 @keyup="checkEmails"
                                 v-model="article.emails.dw"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="widthFirstTd">Ostateczny termin dostawy</td>
                    <td>
                      <div v-if="hasError(`articles.${idx}.delivery_date`)" class="text-danger small">
                        {{ getErrorMessage(`articles.${idx}.delivery_date`) }}
                      </div>
                      <ejs-datepicker
                          :id="'articleDeliveryDate_'+article.id"
                          placeholder="Wybierz datę"
                          :value="article.delivery_date"
                          format="dd.MM.yyyy"
                          @change="(event)=>setDeliveryDate(idx, article, event)"
                          :weekNumber="true"
                      ></ejs-datepicker>
                    </td>
                  </tr>

                  </tbody>
                </table>

              </template>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>
            <form @submit.prevent="saveOrder">
              <button type="submit" class="btn btn-primary" :disabled="form.processing || emailsOk">
                <i class="bi bi-save me-2"></i>Utwórz zamówienie
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal, Tooltip} from "bootstrap";
import {useForm} from "@inertiajs/vue3";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";
import {formatDate} from "v-tables-3/compiled/mixins/methods.js";

export default {
  components: {'ejs-datepicker': DatePickerComponent},
  props: {
    project: Number | String,
    team: String,
    selectedItems: {
      type: Object,
      default: () => {
      }
    },
  },

  data() {
    return {
      errors: {},
      emailsOk: false,
      quoteRequestNumbers: {},
      modalObj: null,
      dueDateFormat: 'dd.MM.yyyy',
      form: useForm({})
    }
  },

  watch: {
    selectedItems: {
      immediate: true,
      handler(newItems) {
        if (!newItems) {
          return false;
        }

        this.form = useForm({
          articles: newItems.map((article) => ({
            emails: {
              to: article.supplier?.customers_data?.email || '',
              dw: ''
            },
            project_id: article.quote_request.project_id,
            team_name: article.quote_request.team_name,
            article_id: article.article.id,
            quote_request: article.quote_request,
            quote_request_item_id: article.quote_request_item_id,
            catalog_number: article.article.catalog_number,
            model: article.article.model,
            supplier: article.supplier,
            quantity_required: article.quantity_required,
            delivery_date: this.formatDate(article.delivery_date),
            producer_short_name: article.article.producer?.short_name || null,
            producer_id: article.article.producer_id || '',
          })),
        });
      },
    },
  },


  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
    // this.fetchQuoteRequestsNumbers();
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },

  methods: {
    checkEmails(event) {
      const value = event.target.value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex do walidacji podstawowej poprawności emaila
      const invalidPatterns = [
        /\.{2,}/,    // Dwie kropki lub więcej
        /,,+/,       // Więcej niż jeden przecinek obok siebie
        /,\./,       // Przecinek przed kropką
        /,\s*\./,    // Przecinek z opcjonalnymi spacjami przed kropką
        /\.,/,       // Kropka przed przecinkiem
      ];

      // Rozdziel string na podstawie przecinków, ignorując puste wpisy
      const emails = value.split(',').map(email => email.trim()).filter(email => email !== '');

      // Sprawdź, czy którykolwiek z niedozwolonych wzorców występuje w wejściu
      const hasInvalidPatterns = invalidPatterns.some(pattern => pattern.test(value));

      // Sprawdź, czy wszystkie maile są prawidłowe oraz brak niedozwolonych wzorców
      const areAllEmailsValid = !hasInvalidPatterns && emails.every(email => emailRegex.test(email));


      // Ustaw błędny mail, jeśli:
      // 1. Nie ma żadnego maila
      // 2. Albo któryś z maili jest nieprawidłowy
      this.emailsOk = !value || !areAllEmailsValid;

      // Zwróć wynik walidacji
      return areAllEmailsValid;
    },

    formatDate(date) {
      if (!date)
        return '';

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },

    emailPattern() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    },

    async fetchQuoteRequestsNumbers(event) {
      let search = event?.target.value || null;
      try {
        const response = await axios.get(route('quoteRequests.numbers.get'), {
          params: {
            quoteRequestNumber: search,
          }
        });

        if (response.data && response.data.quoteRequestNumbers) {
          this.formatQuoteRequestsNumbers(response.data.quoteRequestNumbers);
        }
      } catch (errors) {
        this.$emit('error', errors);
      }

      return false;
    },

    formatQuoteRequestsNumbers(numbers) {
      this.quoteRequestNumbers = numbers.map(number => ({
        label: number.request_number,
        value: number.request_number
      }));
    },

    addEmailsRow() {
      // Znajdź ostatni indeks w `form.emails`
      const lastIndex = Object.keys(this.form.emails).length;

      // Dodaj nowy wiersz
      this.$set(this.form.emails, lastIndex, {
        to: '',
        dw: ''
      });
    },

    removeEmailsRow(idx) {
      // Usuń wiersz z obiektu `form.emails`
      this.$delete(this.form.emails, idx);

      // Przebuduj obiekt `form.emails`, aby zachować ciągłość indeksów
      const updatedEmails = {};
      Object.keys(this.form.emails).forEach((key, newIdx) => {
        updatedEmails[newIdx] = this.form.emails[key];
      });

      // Przypisz zaktualizowany obiekt
      this.form.emails = updatedEmails;
    },

    setDeliveryDate(idx, article, event) {
      this.form.articles[idx].delivery_date = event.value;
    },

    saveOrder() {
      this.form.post(route('orderLists.store'), {
        onSuccess: (resp) => {
          this.$emit('added-success', 'Zamówienie zostało utworzone.');
          this.hide();
        },
        onError: (errors) => {
          console.log('errors:', errors);
          this.errors = errors;
          let errorMessages = Object.values(errors).flat().join(', ');
          this.$emit('added-error', 'Błąd podczas tworzenia zamówienia: ' + errorMessages);
        }
      });
    },

    hasError(field) {
      const error = this.errors && this.errors[field];
      return error;
    },

    getErrorMessage(field) {
      if (!this.errors[field]) {
        return '';
      }

      if (/^Pole .*? jest wymagane\.$/.test(this.errors[field])) {
        return 'To pole jest wymagane.';
      }

      return 'To pole ma niepoprawną wartość';
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}
</script>


<style scoped>
.widthFirstTd {
  width: 150px;
}

.order-list-table:not(:first-child) {
  border-top: 2px solid #222;
}
</style>