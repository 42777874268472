<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">

        <projectMenu :project="project"></projectMenu>

        <div class="widget-content">
          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <div class="card-body">
            <div class="text-end mb-3">
              <ProjectsHistory
                  :project="project"
                  :model="'NumberingHistory'">
              </ProjectsHistory>
            </div>

            <div class="row">
              <div class="col-md-12 col-lg-12">
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>Konstruktor</th>
                    <th>Nazwa podzespołu</th>
                    <th>Numer podzespołu: Konstrukcja</th>
                    <th>Numer podzespołu: Automatyka</th>
                    <th>Ilość maszyn</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(number, index) in numbering" :key="index">
                    <td>{{ number.id }}</td>
                    <td>
                      <span v-show="!number.isEditing">{{
                          users.find(user => user.id === number?.constructor_id)?.first_name ?? ''
                        }} {{ users.find(user => user.id === number?.constructor_id)?.last_name ?? '' }}</span>
                      <select class="form-select form-select-sm" v-show="number.isEditing"
                              v-model="number.constructor_id">
                        <option value=""></option>
                        <option v-for="user in users" :value="user.id">{{ user.first_name ?? '' }}
                          {{ user.last_name ?? '' }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <span v-show="!number.isEditing">{{ number.team_name }}</span>
                      <input type="text" class="form-control form-control-sm" v-show="number.isEditing"
                             v-model="number.team_name"/>
                    </td>
                    <td>
                      <span v-show="!number.isEditing">{{ number.team_name_construction }}</span>
                      <input type="text" class="form-control form-control-sm" v-show="number.isEditing"
                             v-model="number.team_name_construction"/>
                    </td>
                    <td>
                      <span v-show="!number.isEditing">{{ number.team_name_automation }}</span>
                      <input type="text" class="form-control form-control-sm" v-show="number.isEditing"
                             v-model="number.team_name_automation"/>
                    </td>
                    <td>
                      <span v-show="!number.isEditing">{{ number.quantity }}</span>
                      <input type="number" class="form-control form-control-sm" v-show="number.isEditing"
                             v-model="number.quantity"/>
                    </td>

                    <td class="text-end">
                      <button v-show="number.isEditing" class="mb-1 me-1 btn btn-sm"
                              :class="number.isEditing ? 'btn-primary' : 'btn-warning'" @click="editNumbering(number)">
                        Zapisz
                      </button>
                      <button class="mb-1 btn btn-sm"
                              :class="number.isEditing ? 'btn-outline-default' : 'btn-secondary'"
                              @click="toggleEdit(number)">
                        <span v-if="number.isEditing">Anuluj</span> <span v-else>
                            <i class="bi bi-pen"></i>
                          </span>
                      </button>
                      <button v-show="!number.isEditing" class="ms-1 mb-1 btn btn-sm btn-danger"
                              @click="confirmDeleteNumbering(index, number)">
                        <i class="bi bi-trash"></i>
                      </button>
                    </td>
                  </tr>
                  <tr v-if="isAddingNumbering">
                    <td></td>
                    <td>
                      <select autocomplete="off" class="form-select form-select-sm"
                              v-model="newNumbering.constructor_id" name="constructor_id">
                        <option value=""></option>
                        <option v-for="user in users" :value="user.id">{{ user.first_name ?? '' }}
                          {{ user.last_name ?? '' }}
                        </option>
                      </select>
                    </td>
                    <td>
                      <input type="text" autocomplete="off" class="form-control" v-model="newNumbering.team_name"
                             placeholder="Nazwa podzespołu"/>
                    </td>
                    <td>
                      <input type="text" autocomplete="off" class="form-control"
                             v-model="newNumbering.team_name_construction" placeholder="Numer Podzespołu: konstrukcja"/>
                    </td>
                    <td>
                      <input type="text" autocomplete="off" class="form-control"
                             v-model="newNumbering.team_name_automation" placeholder="Numer Podzespołu: automatyka"/>
                    </td>
                    <td>
                      <input type="number" autocomplete="off" class="form-control"
                             v-model="newNumbering.quantity" placeholder="Ilość maszyn"/>
                    </td>
                    <td class="text-end w-25">
                      <button class="mb-1 btn btn-sm btn-outline-primary" @click="addNumbering">Dodaj</button>
                      <button class="ms-1 mb-1 btn btn-sm btn-outline-warning" @click="cancelAddNumbering">Anuluj
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <button v-if="hasPermission('canEditAdvancedProject')" class="btn btn-primary mt-3"
                        @click="toggleAddingNumbering">Dodaj numerację
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script>
import AppLayout from "@/Layouts/App.vue";
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import projectMenu from "@/Pages/Projects/parts/projectMenu.vue";
import ProjectsHistory from "@/Pages/Projects/Histories/ProjectsHistory.vue";

import {ref} from "vue";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";

const {hasPermission} = usePermission();

export default {
  components: {ProjectsHistory, AppLayout, projectMenu},
  props: {
    project: Object,
    users: Object,
    numbering: Object
  },
  setup(props) {
    let newNumbering = ref({
      id: null,
      constructor_id: '',
      team_name: '',
      team_name_construction: '',
      team_name_automation: '',
      quantity: '',
    });

    const isAddingNumbering = ref(false);

    const addNumbering = () => {
      const dataToSend = {
        constructor_id: newNumbering.value.constructor_id,
        team_name: newNumbering.value.team_name,
        team_name_construction: newNumbering.value.team_name_construction,
        team_name_automation: newNumbering.value.team_name_automation,
        quantity: newNumbering.value.quantity
      };
      // Dodaj nowego suppliera do db
      axios.post(route('project.numbering.store', props.project), dataToSend)
          .then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            })

            props.numbering.push({...response.data.new_numbering});
            isAddingNumbering.value = false;
          })
          .catch(error => {
            // Obsługa błędu
            console.error('Błąd podczas dodawania numeracji:', error);
          })
          .finally(() => {
            // Zresetuj formularz
            newNumbering.value = {
              id: null,
              constructor_id: '',
              team_name: '',
              team_name_construction: '',
              team_name_automation: '',
              quantity: ''
            };
          });
    };

    const toggleAddingNumbering = () => {
      isAddingNumbering.value = !isAddingNumbering.value;
    };

    const toggleEdit = (numbering) => {
      if (numbering.isEditing) {
        // Anulowanie edycji- przywróć oryginalne dane
        numbering.constructor_id = numbering.original_constructor_id;
        numbering.team_name = numbering.original_team_name;
        numbering.team_name_construction = numbering.original_team_name_construction1;
        numbering.team_name_automation = numbering.original_team_name_automation;
        numbering.quantity = numbering.original_quantity;
      } else {
        // Rozpoczęcie edycji- zapisz oryginalne dane
        numbering.original_constructor_id = numbering.constructor_id;
        numbering.original_team_name = numbering.team_name;
        numbering.original_team_name_construction1 = numbering.team_name_construction;
        numbering.original_team_name_automation = numbering.team_name_automation;
        numbering.original_quantity = numbering.quantity;
      }
      numbering.isEditing = !numbering.isEditing;
    };

    const editNumbering = (number) => {
      axios.post(route('project.numbering.update', {
        project: props.project,
        numbering: number.id
      }), number)
          .then(response => {
            Toast.fire({
              toast: true,
              icon: response.data.icon,
              title: response.data.title,
              html: response.data.message,
              showClass: {popup: 'animate__animated animate__fadeInDown'},
              hideClass: {popup: 'animate__animated animate__fadeOutUp'},
              timer: 2500,
            });

            // Zaktualizuj dane numeracji po pomyślnym zapisaniu
            const updatedData = response.data.numbering;
            Object.assign(number, updatedData); // Nadpisz lokalne dane aktualizacją z serwera
            number.isEditing = false; // Wyłącz tryb edycji
          })
          .catch(error => {
            console.error('Błąd podczas edytowania numeracji:', error);
          });
    };


    const confirmDeleteNumbering = (index, number) => {
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie Dostawcy',
        html: 'Czy na pewno chcesz usunąć ten wiersz?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        denyButtonText: 'Nie',
      }).then((result) => {
        if (result.isConfirmed) {
          removeNumbering(index, number);
        }
      })
    };

    const removeNumbering = (index, number) => {
      if (number.id) {
        newNumbering.processing = true;
        axios.post(route('project.numbering.delete', {
          project: props.project,
          numbering: number.id
        }))
            .then(response => {
              Toast.fire({
                toast: true,
                icon: response.data.icon,
                title: response.data.title,
                html: response.data.message,
                showClass: {popup: 'animate__animated animate__fadeInDown'},
                hideClass: {popup: 'animate__animated animate__fadeOutUp'},
                timer: 2500,
              })

              isAddingNumbering.value = false;
            })
            .catch(error => {
              // Obsługa błędu
              console.error('Błąd podczas usuwania dostawcy:', error);
            });
      }

      props.numbering.splice(index, 1);
    };

    const cancelAddNumbering = () => {
      isAddingNumbering.value = false;
    };

    return {
      newNumbering,
      isAddingNumbering,
      toggleEdit,
      addNumbering,
      editNumbering,
      confirmDeleteNumbering,
      toggleAddingNumbering,
      removeNumbering,
      cancelAddNumbering,
    }
  },
  data() {
    return {
      hasPermission
    }
  },
  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    }
  },
}
</script>
