<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six offer-card">
        <div class="widget-heading">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-inline-flex align-items-center">
              <Link :href="route('offers.index')" class="btn btn-sm btn-outline-primary me-2">
                <i class="bi bi-arrow-left me-2"></i> Lista ofert
              </Link>
              <h4 class="me-3 my-0">Oferta</h4>
              <h4 class="active mb-0">
                <span id="offerNumber" class="me-2">{{ offerVersion.number }}</span>
                <span class="badge badge-top badge-success" v-if="offerVersion.is_active">Active</span>
              </h4>

              <div class="me-auto ms-2">
                <button type="button" class="btn btn-sm btn-outline-info"
                        data-bs-toggle="modal"
                        data-bs-target="#offerVersionHistoryModal">
                  <i class="bi bi-clock-history me-2"></i>Historia
                </button>
                <OfferVersionHistoryComponent :offerVersionId="offerVersion.id"></OfferVersionHistoryComponent>
              </div>

              <div class="position-absolute end-0 me-3 d-flex align-items-center">
                <h5>Język oferty:&nbsp;</h5>
                <select id="selectLang" :disabled="isProcessingLang" class="form-select form-select-sm h6 mb-0"
                        style="width: 40px;" @change="handleLanguageChange" v-model="offer.language">
                  <option value="en">EN</option>
                  <option value="pl">PL</option>
                  <option value="de">DE</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="widget-content">

          <div v-if="$page.props.errors">
            <ul style="list-style-type: none; padding: 0" v-for="error in $page.props.errors">
              <li class="alert alert-danger">{{ error }}</li>
            </ul>
          </div>

          <form @submit.prevent="submitOffer">
            <input type="hidden" name="version" v-model="offerVersion.version">
            <div class="row">
              <div class="col-ms-12 mb-5">

                Inne wersje:
                <div v-for="version in offer.versions" class="d-inline-flex">
                  <Link v-if="offerVersion?.version !== version.version"
                        :href="route('offers.show', { offer: offer.id, version: version.version })"
                        class="btn btn-sm me-3">
                    <h6 :class="{'active': offerVersion?.version === version.version}" class="mb-0">
                      {{ version.number }}
                      <span class="badge badge-middle badge-sm badge-success" v-if="version.is_active">Active</span>
                    </h6>
                  </Link>
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-ms-12 col-md-6">
                <label class="form-label" for="title">Tytuł:</label>
                <input class="form-control form-control-sm" id="title" v-model="offerVersion.title"
                       @change="changeTitle"/>
              </div>
              <div class="col-ms-12 col-md-6">
                <label class="form-label" for="contactPersons">Osoby kontaktowe: </label>
                <Vueform>
                  <TagsElement id="contactPersons"
                               name="contact_persons"
                               autocomplete="new-password"
                               :search="true"
                               :native="false"
                               v-model="offerVersion.contact_persons"
                               :items="users"
                               :default="defaultOfferContacts"
                               @change="changeContactPersons"
                  ></TagsElement>
                </Vueform>
              </div>
            </div>

            <div class="row">
              <div class="col-ms-12 col-md-6">
                <label class="form-label" for="customer_id">Klient: </label>
                <template v-if="loading">
                  <div>
                    <div class="spinner-grow text-danger me-2 align-self-center loader-sm "></div>
                  </div>
                </template>
                <template v-else>

                  <Vueform>
                    <SelectElement id="customer_id" :object="true" :native="false" :search="true"
                                   @change="fetchCustomerContacts" label-prop="name" value-prop="id"
                                   name="customer_id" v-model="offer.customer"
                                   :default="offer.customer" autocomplete="off" :items="customers">
                    </SelectElement>
                  </Vueform>

                  <OfferCustomerContactSelectedAvailable
                      v-if="loadedCustomerContacts && customers"
                      :customerContacts="loadedCustomerContacts"
                      :offerCustomerContacts="customerContacts"
                      :key="loadedCustomerContacts" @updateOfferCustomerContacts="handleUpdateOfferCustomerContacts">
                  </OfferCustomerContactSelectedAvailable>

                  <CreateEditCustomer v-if="Object.keys(customers).length > 0" :customers="customers"
                                      :chosenCustomer="offer.customer"
                                      @refreshCustomerList="handleRefreshCustomerList"></CreateEditCustomer>
                </template>

              </div>

              <div class="col-ms-12 col-md-6"></div>
            </div>

            <RichTextEditorComponent class="mt-2"
                                     id="editorIntroduction"
                                     label="Wstęp"
                                     modelName="introduction"
                                     :modelText="offerVersion.introduction"
                                     :hidden="true"
                                     :language="offer.language"
                                     @ctrl-s="modifySaveListener"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorDescription"
                                     label="Opis"
                                     modelName="description"
                                     :modelText="offerVersion.description"
                                     :hidden="false"
                                     :language="offer.language"
                                     @ctrl-s="modifySaveListener"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorTechnicalData"
                                     label="Dane techniczne"
                                     modelName="technical_data"
                                     :modelText="offerVersion.technical_data"
                                     :hidden="false"
                                     :language="offer.language"
                                     @ctrl-s="modifySaveListener"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorPriceDetails"
                                     label="Rozbicie cenowe"
                                     modelName="price_details"
                                     :modelText="offerVersion.price_details"
                                     :hidden="false"
                                     :language="offer.language"
                                     @ctrl-s="modifySaveListener"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <RichTextEditorComponent class="mt-2"
                                     id="editorEpilogue"
                                     label="Zakończenie"
                                     modelName="epilogue"
                                     :modelText="offerVersion.epilogue"
                                     :hidden="true"
                                     :language="offer.language"
                                     @ctrl-s="modifySaveListener"
                                     @updateModel="updateModel">
            </RichTextEditorComponent>

            <div class="row">
              <div class="col-sm-12 col-md-6">

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{}"
                                label="Gwarancja (w miesiącach)"
                                modelName="warranty"
                                :modelText="offerVersion.warranty"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{}"
                                label="Gwarancja+"
                                modelName="warranty_plus"
                                :modelText="offerVersion.warranty_plus"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Opis gwarancji"
                                   modelName="warranty_description"
                                   :modelText="offerVersion.warranty_description"
                                   :language="defaultLang"
                                   :hidden="false"
                                   :info="['Tag [Gwarancja] - wartość pola \'Gwarancja\' przepisuje się do \'Opisu Gwarancji\''
                                 ,'Tag [GwarancjaPlus] - j.w.']"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <InputComponent class="mt-2"
                                type="number"
                                :attributes="{}"
                                label="Czas reakcji serwisowej(w godzinach)"
                                modelName="service_response_hours"
                                :modelText="offerVersion.service_response_hours"
                                :language="offer.language"
                                :hidden="true"
                                @updateModel="updateModel">
                </InputComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Punkty serwisowe"
                                   modelName="service_points"
                                   :modelText="offerVersion.service_points"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Zmiany techniczne"
                                   modelName="technical_changes"
                                   :modelText="offerVersion.technical_changes"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Zmiany koncepcji"
                                   modelName="conceptual_changes"
                                   :modelText="offerVersion.conceptual_changes"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Siła wyższa"
                                   modelName="force_majeure"
                                   :modelText="offerVersion.force_majeure"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Odbiór wstępny FAT"
                                   modelName="initial_FAT_acceptance"
                                   :modelText="offerVersion.initial_FAT_acceptance"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Przygotowanie maszyny do wysyłki"
                                   modelName="preparation_for_shipping_machine"
                                   :modelText="offerVersion.preparation_for_shipping_machine"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Dostawa"
                                   modelName="delivery_desc"
                                   :modelText="offerVersion.delivery_desc"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Montaż"
                                   modelName="assembly"
                                   :modelText="offerVersion.assembly"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <label class="form-label">Termin realizacji:</label>
                <div class="d-block pt-2">
                  <label class="w-100 switch s-icons s-outline s-outline-success"
                         style="padding-left: 56px; padding-top: 2px;" for="completTimStarFroContSub">
                    <input type="checkbox" id="completTimStarFroContSub" autocomplete="off"
                           name="completion_time_start_from_contract_submission"
                           v-model="offerVersion.completion_time_start_from_contract_submission"/>
                    <span class="slider round"></span> Liczony od przesłania umowy </label>
                </div>

                <div class="d-inline-flex" v-if="!offerVersion.completion_time_start_from_contract_submission">
                  <div class="btn-group" role="group" aria-label="Termin realizacji">
                    <input type="radio" class="btn-check" name="completion_time_format" value="cw" id="ct_cw"
                           autocomplete="off" @change="changeCompletionTimeFormat"
                           v-model="offerVersion.completion_time_format"
                           :checked="offerVersion.completion_time_format === 'cw'">
                    <label class="btn btn-outline-primary" for="ct_cw">Tydzień roku</label>

                    <input type="radio" class="btn-check" name="completion_time_format" value="" id="ct_date"
                           autocomplete="off" @change="changeCompletionTimeFormat"
                           v-model="offerVersion.completion_time_format"
                           :checked="offerVersion.completion_time_format === null">
                    <label class="btn btn-outline-primary" for="ct_date">Data</label>
                  </div>
                </div>

                <div class="d-inline-flex ms-2" v-if="!offerVersion.completion_time_start_from_contract_submission">
                  <div class="input-group" v-if="offerVersion.completion_time_format === 'cw'">
                    <div class="input-group-text" id="btnGroupAddon">CW</div>
                    <input type="text" class="form-control form-control-sm" style="min-width: 150px; max-width: 200px"
                           placeholder="Tydzień roku" aria-label="Tydzień roku" aria-describedby="btnGroupAddon"
                           v-model="offerVersion.completion_time">
                  </div>
                  <div class="input-group" v-else>
                    <input type="date" class="form-control form-control-sm" style="min-width: 150px;max-width: 200px"
                           aria-label="Data" aria-describedby="btnGroupAddon" v-model="offerVersion.completion_time">
                  </div>
                </div>

                <div class="d-inline-flex ms-2" v-if="offerVersion.completion_time_start_from_contract_submission">

                  <div class="btn-group" role="group" aria-label="Termin realizacji">
                    <input type="radio" class="btn-check" name="completion_time_format" value="months"
                           @updateModel="updateModel" id="completion_time_unit_months" autocomplete="off"
                           v-model="offerVersion.completion_time_unit"
                           :checked="offerVersion.completion_time_unit === 'months'">
                    <label class="btn btn-outline-primary" for="completion_time_unit_months">Miesiące</label>

                    <input type="radio" class="btn-check" name="completion_time_format" value="weeks"
                           @updateModel="updateModel" id="completion_time_unit_weeks" autocomplete="off"
                           v-model="offerVersion.completion_time_unit"
                           :checked="offerVersion.completion_time_unit === 'weeks'">
                    <label class="btn btn-outline-primary" for="completion_time_unit_weeks">Tygodnie</label>
                  </div>

                  <input type="number" step="1" min="1" max="999" class="ms-2 form-control form-control-sm"
                         style="min-width: 250px; height: 40px" placeholder="od przesłania oferty"
                         v-model="offerVersion.completion_time" aria-label="Tydzień roku"
                         aria-describedby="btnGroupAddon">
                </div>
              </div>

              <div class="col-sm-12 col-md-6">
                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Uruchomienie"
                                   modelName="launch"
                                   :modelText="offerVersion.launch"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Szkolenie / Wsparcie"
                                   modelName="support"
                                   :modelText="offerVersion.support"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Odbiór końcowy SAT"
                                   modelName="final_SAT_acceptance"
                                   :modelText="offerVersion.final_SAT_acceptance"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Dokumentacja"
                                   modelName="documentation"
                                   :modelText="offerVersion.documentation"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Oznakowanie CE"
                                   modelName="ce_certification"
                                   :modelText="offerVersion.ce_certification"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Dostawa"
                                   modelName="delivery"
                                   :modelText="offerVersion.delivery"
                                   :language="offer.language"
                                   :hidden="false"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Warunki płatności"
                                   modelName="term_of_payment"
                                   :modelText="offerVersion.term_of_payment"
                                   :language="offer.language"
                                   :hidden="false"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Kod pocztowy dostawy"
                                modelName="zip_code_delivery"
                                :modelText="offerVersion.zip_code_delivery"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Miasto dostawy"
                                modelName="city_delivery"
                                :modelText="offerVersion.city_delivery"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Adres dostawy (ulica i numer budynku)"
                                modelName="street_delivery"
                                :modelText="offerVersion.street_delivery"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="text"
                                :attributes="{}"
                                label="Numer lokalu dostawy"
                                modelName="apartment_delivery"
                                :modelText="offerVersion.apartment_delivery"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <InputComponent class="mt-2"
                                type="price"
                                :attributes="{min:0, step:0.01}"
                                label="Cena (netto)"
                                modelName="price"
                                :modelText="offerVersion.price"
                                :language="offer.language"
                                :hidden="false"
                                @updateModel="updateModel">
                </InputComponent>

                <SelectComponent class="mt-2"
                                 :attributes="{}"
                                 label="Waluta"
                                 modelName="currency"
                                 :modelText="offerVersion.currency"
                                 :language="offer.language"
                                 :hidden="false"
                                 :options="[{'PLN': 'PLN'}, {'EUR': 'EUR'}, {'USD': 'USD'}]"
                                 @updateModel="updateModel">
                </SelectComponent>

                <TextAreaComponent class="mt-2"
                                   :attributes="{}"
                                   label="Uwagi"
                                   modelName="comments"
                                   :modelText="offerVersion.comments"
                                   :language="offer.language"
                                   :hidden="true"
                                   @updateModel="updateModel">
                </TextAreaComponent>

                <InputDateComponent class="mt-2"
                                    type="date"
                                    :attributes="{}"
                                    label="Data ważności oferty"
                                    modelName="expiration_date"
                                    :modelText="offerVersion.expiration_date"
                                    :language="offer.language"
                                    :hidden="false"
                                    @updateModel="updateModel">
                </InputDateComponent>

                <InputDateComponent class="mt-2"
                                    type="date"
                                    :attributes="{}"
                                    label="Data utworzenia oferty"
                                    modelName="created_at"
                                    :modelText="offerVersion.created_at"
                                    :language="offer.language"
                                    :hidden="false"
                                    @updateModel="updateModel">
                </InputDateComponent>

                <SelectComponent class="mt-2"
                                 :attributes="{}"
                                 label="Status"
                                 modelName="status_id"
                                 :modelText="offerVersion.status_id"
                                 :language="offer.language"
                                 :hidden="false"
                                 :options="computedStatuses"
                                 @updateModel="updateModel">
                </SelectComponent>

                <div class="row mt-4 align-items-center d-none">
                  <div class="col-auto">
                    <label class="form-label" for="status">Aktywna wersja:</label>
                  </div>
                  <div class="col-auto">
                    <label class="switch s-icons s-outline s-outline-success mb-3 h-100">
                      <input type="checkbox" v-model="offerVersion.is_active">
                      <span class="slider round"></span> </label>
                  </div>
                </div>

              </div>
            </div>


            <div class="mt-5 pt-3 mb-4" style="border-top: 1px solid #d0d0d0;">
              <h4>Pliki oferty</h4>
            </div>

            <div class="row">
              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_materials_for_offer">Materiały do oferty</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_materials_for_offer"
                                    :sort="false"
                                    :object="true"
                                    :default="offerVersionFiles.file_materials_for_offer"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeTempFile"
                                    :remove-endpoint="removeFile"
                                    :submit="false"
                                    :url="`/storage/app/files/offer/${this.offerVersion.id}/file_materials_for_offer/`"
                                    name="files[file_materials_for_offer]"
                  />
                </Vueform>
              </div>

              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_client_request">Zapytanie klienta</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_client_request"
                                    :sort="false"
                                    :object="true"
                                    :default="offerVersionFiles.file_client_request"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeTempFile"
                                    :remove-endpoint="removeFile"
                                    :submit="false"
                                    :url="`/storage/app/files/offer/${this.offerVersion.id}/file_client_request/`"
                                    name="files[file_client_request]"
                  />
                </Vueform>
              </div>

              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_estimate">Kosztorys</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_estimate"
                                    :sort="false"
                                    :object="true"
                                    :default="offerVersionFiles.file_estimate"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeTempFile"
                                    :remove-endpoint="removeFile"
                                    :submit="false"
                                    :url="`/storage/app/files/offer/${this.offerVersion.id}/file_estimate/`"
                                    name="files[file_estimate]"
                  />
                </Vueform>
              </div>

              <div class="col-md-4 col-lg col-xl pb-3">
                <label class="form-label" for="file_contract">Umowa</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_contract"
                                    :sort="false"
                                    :object="true"
                                    :default="offerVersionFiles.file_contract"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeTempFile"
                                    :remove-endpoint="removeFile"
                                    :submit="false"
                                    :url="`/storage/app/files/offer/${this.offerVersion.id}/file_contract/`"
                                    name="files[file_contract]"
                  />
                </Vueform>
              </div>

              <div class="col-md-6 col-lg col-xl pb-3">
                <label class="form-label" for="file_others">Inne</label>
                <Vueform>
                  <MultifileElement :drop="true"
                                    ref="file_others"
                                    :sort="false"
                                    :object="true"
                                    :default="offerVersionFiles.file_others"
                                    :upload-temp-endpoint="uploadTempFile"
                                    :remove-temp-endpoint="removeTempFile"
                                    :remove-endpoint="removeFile"
                                    :submit="false"
                                    :url="`/storage/app/files/offer/${this.offerVersion.id}/file_others/`"
                                    view="file"
                                    name="files[file_others]"
                  />
                </Vueform>
              </div>
            </div>

            <hr>

            <div class="section">
              <div class="d-flex align-items-center justify-content-center">
                <div class="w-50 text-end">
                  <offerPdfPreviewGenerator class="me-3" :offer="offer" :formData="offerVersion"
                                            :customer="offer.customer"
                                            :contact_persons="offerVersion.contact_persons"
                                            aria-action="offerPdf.preview"/>
                  <offerPdfDownloadGenerator :customer="offer.customer" :offer="offer" :formData="offerVersion"
                                             aria-action="offerPdf.preview"/>
                </div>

                <div class="ms-auto">
                  <button v-if="canModifyVersion()" id="modifyVersionBtn"
                          :class="{ 'floating-button': floatingButtonVisible }" class="btn btn-primary me-3"
                          type="submit"
                          @click="setClickedSubmitButton('modifyVersion')" value="modifyVersion" :disabled="loading">
                      <span v-if="loading"
                            class="spinner-border spinner-border-sm text-white me-2 align-self-center loader-sm">
                        Ładuję...</span> Zapisz ofertę
                  </button>

                  <button class="btn btn-primary float-end" type="submit"
                          @click="setClickedSubmitButton('saveAsNewVersion')" value="saveAsNewVersion"
                          :disabled="loading">
                        <span v-if="loading"
                              class="spinner-border spinner-border-sm text-white me-2 align-self-center loader-sm">
                          Ładuję...</span> Zapisz jako nową wersję
                  </button>
                </div>
              </div>
            </div>

          </form>


        </div>
      </div>
    </div>
  </AppLayout>
</template>


<script>

import {Link, usePage} from "@inertiajs/vue3";
import {nextTick, reactive, ref} from "vue";
import AppLayout from "@/Layouts/App.vue";

import CreateEditCustomer from "@/Pages/Modals/CreateEditCustomer.vue"

import offerPdfPreviewGenerator from "@/Components/OfferPdfPreviewGenerator.vue";
import offerPdfDownloadGenerator from "@/Components/OfferPdfDownloadGenerator.vue";
import OfferCustomerContactSelectedAvailable from "@/Pages/Offers/offerCustomerContactSelectedAvailable.vue";

import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';
import 'select2/dist/css/select2.min.css';

import OfferVersionHistoryComponent from '@/Pages/Modals/OfferVersionHistoryComponent.vue';

import RichTextEditorComponent from "@/Pages/Offers/form-elements/RichTextEditorComponent.vue";
import InputComponent from "@/Pages/Offers/form-elements/InputComponent.vue";
import TextAreaComponent from "@/Pages/Offers/form-elements/TextAreaComponent.vue";
import SelectComponent from "@/Pages/Offers/form-elements/SelectComponent.vue";
import InputDateComponent from "./form-elements/InputDateComponent.vue";
import tinymce from "tinymce";

export default {
  components: {
    InputDateComponent,
    OfferVersionHistoryComponent,
    CreateEditCustomer,
    SelectComponent,
    TextAreaComponent,
    InputComponent,
    RichTextEditorComponent,
    AppLayout,
    Link,
    offerPdfPreviewGenerator,
    offerPdfDownloadGenerator,
    OfferCustomerContactSelectedAvailable
  },

  props: {
    thisOffer: Object,
    thisOfferVersion: Object,
    users: Array,
    authUser: Object,
    defaultOfferContacts: Object,
    statuses: Object,
    files: Object,
    offersTemplatePL: Object,
    offersTemplateEN: Object,
    offersTemplateDE: Object,
  },

  setup() {

    const {props} = usePage();

    let offer = ref(props.thisOffer);
    // ustaw defaultowy język
    offer._rawValue.language = offer._rawValue.language ?? 'pl';

    let initialValues = {
      pl: {
        introduction: props.offersTemplatePL.offer_introduction,
        description: props.offersTemplatePL.offer_description,
        price_details: props.offersTemplatePL.offer_price_details,
        technical_data: props.offersTemplatePL.offer_technical_data,
        epilogue: props.offersTemplatePL.offer_epilogue,
        warranty: props.offersTemplatePL.offer_warranty,
        warranty_plus: props.offersTemplatePL.offer_warranty_plus,
        warranty_description: props.offersTemplatePL.offer_warranty_description,
        service_response_hours: props.offersTemplatePL.offer_service_response_hours,
        term_of_payment: props.offersTemplatePL.offer_term_of_payment,
        service_points: props.offersTemplatePL.offer_service_points,
        technical_changes: props.offersTemplatePL.offer_technical_changes,
        conceptual_changes: props.offersTemplatePL.offer_conceptual_changes,
        force_majeure: props.offersTemplatePL.offer_force_majeure,
        comments: props.offersTemplatePL.offer_comment,
        initial_FAT_acceptance: props.offersTemplatePL.offer_initial_FAT_acceptance,
        preparation_for_shipping_machine: props.offersTemplatePL.offer_preparation_for_shipping_machine,
        assembly: props.offersTemplatePL.offer_assembly,
        launch: props.offersTemplatePL.offer_launch,
        support: props.offersTemplatePL.offer_support,
        final_SAT_acceptance: props.offersTemplatePL.offer_final_SAT_acceptance,
        documentation: props.offersTemplatePL.offer_documentation,
        ce_certification: props.offersTemplatePL.offer_ce_certification,
      },
      en: {
        introduction: props.offersTemplateEN.offer_introduction,
        description: props.offersTemplateEN.offer_description,
        price_details: props.offersTemplateEN.offer_price_details,
        technical_data: props.offersTemplateEN.offer_technical_data,
        epilogue: props.offersTemplateEN.offer_epilogue,
        warranty: props.offersTemplateEN.offer_warranty,
        warranty_plus: props.offersTemplateEN.offer_warranty_plus,
        warranty_description: props.offersTemplateEN.offer_warranty_description,
        service_response_hours: props.offersTemplateEN.offer_service_response_hours,
        term_of_payment: props.offersTemplateEN.offer_term_of_payment,
        service_points: props.offersTemplateEN.offer_service_points,
        technical_changes: props.offersTemplateEN.offer_technical_changes,
        conceptual_changes: props.offersTemplateEN.offer_conceptual_changes,
        force_majeure: props.offersTemplateEN.offer_force_majeure,
        comments: props.offersTemplateEN.offer_comment,
        initial_FAT_acceptance: props.offersTemplateEN.offer_initial_FAT_acceptance,
        preparation_for_shipping_machine: props.offersTemplateEN.offer_preparation_for_shipping_machine,
        assembly: props.offersTemplateEN.offer_assembly,
        launch: props.offersTemplateEN.offer_launch,
        support: props.offersTemplateEN.offer_support,
        final_SAT_acceptance: props.offersTemplateEN.offer_final_SAT_acceptance,
        documentation: props.offersTemplateEN.offer_documentation,
        ce_certification: props.offersTemplateEN.offer_ce_certification,
      },
      de: {
        introduction: props.offersTemplateDE.offer_introduction,
        description: props.offersTemplateDE.offer_description,
        epilogue: props.offersTemplateDE.offer_epilogue,
        technical_data: props.offersTemplateDE.offer_technical_data,
        price_details: props.offersTemplateDE.offer_price_details,
        warranty: props.offersTemplateDE.offer_warranty,
        warranty_plus: props.offersTemplateDE.offer_warranty_plus,
        warranty_description: props.offersTemplateDE.offer_warranty_description,
        service_response_hours: props.offersTemplateDE.offer_service_response_hours,
        term_of_payment: props.offersTemplateDE.offer_term_of_payment,
        service_points: props.offersTemplateDE.offer_service_points,
        technical_changes: props.offersTemplateDE.offer_technical_changes,
        conceptual_changes: props.offersTemplateDE.offer_conceptual_changes,
        force_majeure: props.offersTemplateDE.offer_force_majeure,
        comments: props.offersTemplateDE.offer_comment,
        initial_FAT_acceptance: props.offersTemplateDE.offer_initial_FAT_acceptance,
        preparation_for_shipping_machine: props.offersTemplateDE.offer_preparation_for_shipping_machine,
        assembly: props.offersTemplateDE.offer_assembly,
        launch: props.offersTemplateDE.offer_launch,
        support: props.offersTemplateDE.offer_support,
        final_SAT_acceptance: props.offersTemplateDE.offer_final_SAT_acceptance,
        documentation: props.offersTemplateDE.offer_documentation,
        ce_certification: props.offersTemplateDE.offer_ce_certification,
      }
    }


    return {
      initialValues,
      defaultLang: offer._rawValue.language,
    };
  },

  data() {
    // ustaw pliki do offer
    let offerVersion = this.thisOfferVersion;
    let offerVersionFiles = {};
    if (this.files && Object.keys(this.files).length > 0) {
      offerVersionFiles = this.files;
    }

    return {
      // defaultPerson,
      offer: this.thisOffer,
      offerVersion,
      isProcessingLang: false,
      floatingButtonVisible: true,
      loading: false,
      saveButtonType: false,
      offerVersionFiles,
      loadedCustomerContacts: [],
      customers: [],
      customerContacts: this.thisOffer.offer_customer_contacts,
      chosenCustomer: this.thisOffer.customer,
      selectedCustomerId: this.thisOffer.customer.id
    }
  },

  mounted() {
    this.fetchCustomers();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', this.modifySaveListener);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('keydown', this.modifySaveListener);
  },

  computed: {
    computedStatuses() {
      let statuses = [];
      this.statuses.forEach(function (status, idx) {
        statuses.push({[status.id]: status.status});
      })
      return statuses;
    },

    previewUrl() {
      return `/files/offer/${this.offerVersion.id}/file_others/`;
    },
  },

  methods: {
    handleRefreshCustomerList() {
      this.fetchCustomers();
    },

    async fetchCustomers() {
      this.loading = true;
      const self = this;

      axios.get(route('get-customers-json')).then(response => {
        this.customers = response.data;
        this.customersLoaded = true;
        this.loading = false;
        this.$nextTick(() => {
          self.fetchCustomerContacts(this.offer.customer);
        });
      }).catch(error => {
        console.log(error);
      })
    },

    async fetchCustomerContacts(customer) {
      if (customer) {

        // jeżeli customer został zmieniony, to usuń wszystkie kontakty, które są wybrane do oferty
        if (customer.id !== this.offer.customer.id) {
          this.offer.offer_customer_contacts = [];
        }

        this.selectedCustomerId = customer.id;
        this.offer.customer = customer;
        this.offer.customer_id = customer.id;
      }

      this.customerContacts = [];

      if (this.selectedCustomerId) {
        // Wybrano klienta, pobierz jego osoby kontaktowe
        let selectedCustomer = this.customers.find(customer => customer.id === this.selectedCustomerId);
        this.loadedCustomerContacts = selectedCustomer.customers_contacts ?? [];

        // zaktualizuj pozycje w ofercie o nowe daje kontakowe
        this.offer.offer_customer_contacts.forEach((contact, idx) => {
          this.offer.offer_customer_contacts[idx] = this.loadedCustomerContacts.find(cont => cont.id === contact.id)
        })

        this.customerContacts = this.offer.offer_customer_contacts;
      } else {
        // Nie wybrano klienta, wyczyść opcje
        this.customerContacts = [];
      }
    },

    changeCompletionTimeFormat() {
      this.offerVersion.completion_time_format = event.target.value;
    },

    handleLanguageChange() {
      let chosenLang = event.target.value;
      let self = this;

      Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Zmienić język oferty na ' + chosenLang.toUpperCase() + '?',
        html: '<h4>Są dwie możliwości do wyboru:</h4><ul style="text-align: left"><li>Zmiana całego szablonu: wszystkie wypełnione pola zostaną zmienione na szablon wybranego języka.</li><li>Zmian nagłówków: zmienią się jedynie nagłówki oferty na wybrany język (zawartość pól zostanie nienaruszona).</li></ul><p>Pamiętaj, że zmiana języka dotyczy całej oferty, a nie tylko danej wersji.</p>',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Zmień cały szablon',
        confirmButtonColor: 'success',
        showDenyButton: true,
        denyButtonText: 'Zmień tylko nagłówki',
        denyButtonColor: '#1abc9c',
        showCancelButton: true,
        cancelButtonText: 'Anuluj',
      }).then((result) => {
        if (result.isConfirmed) {

          // zmień całą ofertę na nowy język
          self.offer.language = chosenLang;
          self.defaultLang = chosenLang;

          // podmień szablon do oferty
          self.offerVersion = {...self.offerVersion, ...self.initialValues[chosenLang]};

          // zaktualizuj pola z tinymce
          setTimeout(() => {
            if (self.offerVersion.introduction)
              tinymce.get('textarea_introduction').setContent(self.offerVersion.introduction);
            if (self.offerVersion.epilogue)
              tinymce.get('textarea_epilogue').setContent(self.offerVersion.epilogue);
            if (self.offerVersion.price_details)
              tinymce.get('textarea_price_details').setContent(self.offerVersion.price_details);
            if (self.offerVersion.description)
              tinymce.get('textarea_description').setContent(self.offerVersion.description);
            if (self.offerVersion.technical_data)
              tinymce.get('textarea_technical_data').setContent(self.offerVersion.technical_data);
          }, 100);

        } else if (result.isDenied) {
          // zmień tylko nagłówki oferty na wybrany język
          this.offer.language = chosenLang;
          this.defaultLang = chosenLang;
          document.getElementById('modifyVersionBtn').click();
        } else {
          // anuluj zmianę jezyka
          this.offer.language = this.defaultLang;
        }
      })
    },

    async uploadTempFile(item, formData) {
      this.loading = true
      const self = this;
      let resp;

      await axios.post(route('upload-temp-file'), {file: item, path: formData.path},
          {headers: {'Content-Type': 'multipart/form-data',}}
      ).then(response => {
        const subFolder = response.data.subFolder;

        if (!self.offerVersionFiles[subFolder]) {
          this.$set(self.offerVersionFiles, subFolder, []);
        }

        self.offerVersionFiles[subFolder].push({
          path: response.data.path,
          subFolder: response.data.subFolder,
          tmp: response.data.tmp,
          originalName: response.data.originalName,
          isTemporary: response.data.isTemporary
        });

        resp = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.loading = false;
      });

      return resp;
    },

    removeTempFile(item, formData) {
      this.offerVersionFiles[item.subFolder] = this.offerVersionFiles[item.subFolder].filter((file) => file.tmp !== item.tmp);
      return true;
    },

    removeFile(item, formData) {
      const fileName = item;
      const fileUrl = formData.fileUrl;
      const _fieldId = formData.fieldId;
      const _matches = _fieldId.match(/\[([^\]]+)\]/g);
      const subFolder = _matches.map(match => match.slice(1, -1))[0] || null;

      if (!subFolder) {
        return false;
      }

      // usuń item z listy plików w ofercie
      this.offerVersionFiles[subFolder] = this.offerVersionFiles[subFolder].filter((file) => file.tmp !== fileName);
    },

    async submitOffer() {
      //this.loading = true;
      let params = {};
      let self = this;

      params.saveButtonType = this.saveButtonType;

      if (this.saveButtonType === 'saveAsNewVersion') {
        // zapisywanie nowej wersji
        params.newVersionShouldActive = 1;
        if (!await this.ShouldSaveAsNewVersion()) {
          return false;
        }
      }

      // zaktualizuj wybranego klienta
      this.offer.customer_id = this.selectedCustomerId;

      axios.post('/offers/' + this.offer.id + '/' + this.offerVersion.id + '/update', {
        offer: this.offer,
        offerVersion: this.offerVersion,
        params: params,
        offerVersionFiles: self.offerVersionFiles
      }).then(response => {

        Toast.fire({
          position: 'top-end',
          toast: true,
          icon: response.data.icon,
          title: response.data.title,
          html: response.data.message,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: 2500,
          timerProgressBar: true,
          showConfirmButton: false,
          showCloseButton: true
        });

        if (response.data.newVersion) {
          let newVersion = response.data.newVersion.version;
          let url = '/offers2/' + self.offer.id + '?version=' + newVersion;
          document.location = route('offers.show', {offer: self.offer}) + '?version=' + newVersion;
        }

        this.$nextTick(() => {
          this.loading = false;
          self.$makeClean();
        })
      });
    },

    canModifyVersion() {
      return this.offerVersion.is_active;
    },

    async ShouldSaveAsNewVersion() {
      return new Promise((resolve, reject) => {
        Toast.fire({
          toast: false,
          icon: 'warning',
          title: 'Czy utworzyć nową wersję?',
          html: 'Nowa wersja będzie ustawiona jako aktywna.',
          showDenyButton: true,
          showClass: {popup: 'animate__animated animate__fadeInDown'},
          hideClass: {popup: 'animate__animated animate__fadeOutUp'},
          timer: false,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Tak',
          denyButtonText: 'Nie',
        }).then((result) => {
          if (result.isConfirmed) {
            resolve(1);
          } else if (result.isDenied) {
            resolve(0);
          }
        })
      })
    },

    changeContactPersons(newValue) {
      this.offerVersion.contact_persons = newValue;
      this.$makeDirty();
    },

    changeTitle() {
      this.$makeDirty();
    },

    handleUpdateOfferCustomerContacts(updatedOfferCustomerContacts) {
      this.offer.offer_customer_contacts = updatedOfferCustomerContacts;
    },

    updateModel({modelName, value}) {
      this.offerVersion[modelName] = value;
      this.$makeDirty();
    },

    setClickedSubmitButton(buttonName) {
      this.saveButtonType = buttonName;
    },

    handleScroll() {
      // Sprawdź, czy strona została przewinięta do końca card
      this.offerCard = $('.offer-card').height();
      const scrollTop = $(window).scrollTop() + $(window).height();
      this.floatingButtonVisible = this.offerCard > scrollTop;
    },

    mapContactPersons(contactPersons) {
      return contactPersons.map(person => ({
        label: person.name,
        name: person.name,
        id: person.id,
        value: person.id,
        phone: person.phone
      }));
    },

    modifySaveListener(event) {
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        document.getElementById('modifyVersionBtn').click();
      }
    },
  },
}

</script>
