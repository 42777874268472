<template>
  <div class="widget-heading pb-0 d-block">
    <div class="row">

      <div class="col-md-12 col-lg-10">
        <h4 class="d-inline-block h4" style="line-height: 1.4" v-if="!isEditingProjectNameDesc">
          Projekt:
        </h4>
        <div class="d-inline-block h5">
          <b class="ms-2">
          <span class="px-2 py-0" :style="{backgroundColor: project.bg_color, color: project.color }">
            {{ project.number }}
          </span>
            - {{ project.name }}
          </b>
        </div>
        <span class="ms-4 text-danger font-weight-bolder" v-if="project.archived">ZARCHIWIZOWANY</span>

        <div class="d-inline-flex align-items-center">
          <button @click="editProjectNameDesc" style="padding: 5px 7px"
                  class="ms-2 btn btn-outline-primary">
            <i class="bi bi-pen"></i></button>
          <Link :href="route('projects.index')" class="btn btn-outline-primary d-inline-block ms-2">
            <i class="bi bi-people"></i>&nbsp;Lista Projektów
          </Link>
        </div>

      </div>

      <template v-if="isEditingProjectNameDesc">
        <div id="messageProjectNameDesc" class="h5 d-inline-flex ms-3 text-success"></div>
        <div class="d-flex">
          <div style="width: 10%" class="me-2">
            <label>Numer</label>
            <input v-model="project.number" class="form-control form-control-sm me-1">
          </div>
          <div style="width: 30%" class="me-2">
            <label>Nazwa</label>
            <input v-model="project.name" class="form-control form-control-sm me-1">
          </div>
          <div style="width: 30%" class="me-2">
            <label>Opis</label>
            <input v-model="project.description" class="form-control form-control-sm">
          </div>
          <div style="width: 10%" class="me-2">
            <label class="d-block">Kolor tła</label>
            <ejs-colorpicker v-model="project.bg_color"></ejs-colorpicker>
          </div>
          <div style="width: 10%" class="me-2">
            <label class="d-block">Kolor czcionki</label>
            <ejs-colorpicker v-model="project.color"></ejs-colorpicker>
          </div>

          <div class="mt-4" style="padding-top: 7px;">
            <button @click="saveProjectNameDesc" :disabled="savingProjectNameDesc"
                    class="ms-2 mb-1 btn btn-sm btn-primary">
              <i class="bi bi-save me-2"></i>Zapisz
            </button>
            <button @click="cancelProjectNameDesc" :disabled="savingProjectNameDesc"
                    class="ms-2 mb-1 btn btn-sm btn-danger">
              <i class="bi bi-x me-2"></i>Anuluj
            </button>
          </div>
        </div>
      </template>


      <div class="col-md-12 col-lg-2 text-end" v-if="!isEditingProjectNameDesc">
        <form id="deleteProjectForm" class="d-inline-block me-2" method="post" @submit.prevent="deleteProject">
          <input type="hidden" name="_token" :value="csrfToken">
          <button class="btn btn-outline-danger">Usuń projekt</button>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 my-2">
        <form @submit.prevent="submitGlowiceProject">
          <div class="checkbox-success custom-control custom-checkbox d-inline-block">
            <input id="projectGlowice" type="checkbox" class="custom-control-input"
                   :checked="project.glowice" @change="changeProjectGlowice">
            <label for="projectGlowice" class="custom-control-label h6">Projekt głowic</label>
          </div>
          <button v-if="glowiceProjectHasChanged" type="submit" class="btn btn-primary btn-sm ms-2">Zapisz
          </button>
        </form>

        <form @submit.prevent="submitHideOnList" class="mt-3">
          <div class="checkbox-success custom-control custom-checkbox d-inline-block">
            <input id="projectHideOnList" type="checkbox" class="custom-control-input"
                   :checked="project.hide_on_list" @change="changeProjectHideOnList">
            <label for="projectHideOnList" class="custom-control-label h6">Nie pokazuj na liście projektów</label>
          </div>
          <button v-if="hideOnListProjectHasChanged" type="submit" class="btn btn-primary btn-sm ms-2">Zapisz
          </button>
          <div>
            <small class="text-info"><i class="bi bi-arrow-up-circle me-2"></i>Jeśli projekt będzie schowany, priorytet
              będzie ustawiony na najniższy.</small>
          </div>
        </form>
      </div>
      <div class="col-12">
        <p>Opis: {{ project.description ?? '' }}</p>
      </div>
    </div>
  </div>

  <div class="widget-heading pt-0 btn-group">
    <Link :href="route('projects.show', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show') }">Projekt
    </Link>
    <Link :href="route('projects.show.contacts', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.contacts') }">Kontakty projektu
    </Link>
    <Link :href="route('projects.show.selected-suppliers', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.selected-suppliers') }">Wybrani producenci
    </Link>
    <Link :href="route('projects.show.numbering', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.numbering') }">Numeracja
    </Link>
    <Link v-if="hasPermission('canEditTimeSpentAndBudgetProject')"
          :href="route('projects.show.time-spent', {'project': project})" class="btn btn-outline-primary disabled"
          :class="{ 'btn-primary': isRouteActive('projects.show.time-spent') }">Spędzony czas
    </Link>
    <Link v-if="hasPermission('canEditTimeSpentAndBudgetProject')"
          :href="route('projects.show.budget-costs', {'project': project})" class="btn  btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.budget-costs') }">Budżet/koszty
    </Link>
    <Link v-if="hasPermission('canEditBasicProject')" :href="route('projects.show.attachments', {'project': project})"
          class="btn btn-outline-primary " :class="{ 'btn-primary': isRouteActive('projects.show.attachments') }">
      Załączniki
    </Link>
    <Link v-if="hasPermission('canEditAdvancedProject')"
          :href="route('projects.show.risk-assessment', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.risk-assessment') }">Ocena ryzyka
    </Link>
    <Link v-if="hasPermission('canEditAdvancedProject')"
          :href="route('projects.show.project-file', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.project-file') }">Plik projektu
    </Link>
    <Link :href="route('projects.show.tasks', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.tasks') }">Zadania
    </Link>
    <Link v-if="hasPermission('canViewPaymentsProject') || hasPermission('canEditPaymentsProject')"
          :href="route('projects.show.payments', {'project': project})" class="btn btn-outline-primary "
          :class="{ 'btn-primary': isRouteActive('projects.show.payments') }">Płatności
    </Link>
  </div>
</template>

<script>
import route from "../../../../../vendor/tightenco/ziggy/src/js/index.js";
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import {ColorPickerComponent as EjsColorpicker} from "@syncfusion/ej2-vue-inputs";
import {useForm} from "@inertiajs/vue3";

const {hasPermission} = usePermission();

export default {
  components: {hasPermission, EjsColorpicker},
  props: {
    project: Object
  },
  data() {
    return {
      isEditingProjectNameDesc: false,
      oldProjectNumber: false,
      oldProjectName: false,
      oldProjectDescription: false,
      savingProjectNameDesc: false,
      csrfToken: null,
      hasPermission,
      glowiceForm: useForm({
        glowice: this.project.glowice || false
      }),
      glowiceProjectHasChanged: false,
      hideOnListForm: useForm({
        hide_on_list: this.project.hide_on_list || false
      }),
      hideOnListProjectHasChanged: false
    }
  },
  created() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').content
  },

  methods: {
    route,
    isRouteActive(routeName) {
      return route().current() === routeName;
    },
    deleteProject(event) {
      const self = this;
      Toast.fire({
        toast: false,
        icon: 'warning',
        title: 'Usuwanie projektu',
        html: 'Czy na pewno chcesz usunąć ten projekt?',
        showDenyButton: true,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        position: 'center',
        showConfirmButton: true,
        denyButtonText: 'Nie',
        confirmButtonText: 'Tak',
      }).then((result) => {
            if (result.isConfirmed) {
              axios.post(route('projects.destroy', this.project)).then(response => {
                Toast.fire({
                  icon: response.data.icon,
                  title: response.data.title,
                  html: response.data.message,
                });
                if (response.data.icon === 'success') {
                  setTimeout(function () {
                    self.$inertia.visit('/projects');
                  }, 1000);
                }
              });
            }
          }
      )
    },

    editProjectNameDesc() {
      this.isEditingProjectNameDesc = true;
      this.oldProjectNumber = this.project.number;
      this.oldProjectName = this.project.name;
      this.oldProjectDescription = this.project.description;
      this.oldProjectBgColor = this.project.bg_color;
      this.oldProjectColor = this.project.color;
    },

    saveProjectNameDesc() {
      this.savingProjectNameDesc = true;
      axios.post(route('project.update-nameDesc', this.project.id), {
        name: this.project.name,
        number: this.project.number,
        description: this.project.description,
        bg_color: this.project.bg_color,
        color: this.project.color
      }).then(response => {
        let resp = document.getElementById('messageProjectNameDesc');
        resp.innerHTML = response.data;
        setTimeout(function () {
          if (document.getElementById('messageProjectNameDesc'))
            document.getElementById('messageProjectNameDesc').innerHTML = '';
        }, 3000)
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.savingProjectNameDesc = false;
        this.isEditingProjectNameDesc = false;
      })
    },

    cancelProjectNameDesc() {
      this.project.number = this.oldProjectNumber;
      this.project.name = this.oldProjectName;
      this.project.description = this.oldProjectDescription;
      this.project.bg_color = this.oldProjectBgColor;
      this.project.color = this.oldProjectColor;
      this.isEditingProjectNameDesc = false;
    },

    changeProjectGlowice() {
      this.glowiceForm.glowice = event.target.checked;
      this.glowiceProjectHasChanged = true;
    },

    submitGlowiceProject() {
      this.glowiceForm.post(route('projects.set-glowice-project.update', this.project), {
        onSuccess: () => {
          this.glowiceProjectHasChanged = false;
        },
        onError: (error) => {
          console.log(error);
        }
      });
    },

    changeProjectHideOnList() {
      this.hideOnListForm.hide_on_list = event.target.checked;
      this.hideOnListProjectHasChanged = true;
    },

    submitHideOnList() {
      this.hideOnListForm.post(route('projects.change-hide-on-list.update', {project:this.project}), {
        onSuccess: () => {
          this.hideOnListProjectHasChanged = false;
        },
        onError: (error) => {
          console.log(error);
        }
      });
    }
  }
}
</script>
