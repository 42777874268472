<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Stany magazynowe</h4>
              <div class="ms-auto" v-if="isAdmin()">
                <Link class="btn btn-outline-primary btn-sm ms-2" :href="route('articleWarehouses.index')">Magazyny
                </Link>
              </div>
            </div>
            <div class="mt-3 row">

<!--              <div class="col-md-12 col-lg-6">-->
<!--                <label for="project-select">Wybierz magazyn:</label>-->
<!--                <select id="project-select" class="form-select form-select-sm" @change="updateSelectedProject"-->
<!--                        v-model="selectedProjectId">-->
<!--                  <option value="0" selected>Wszystkie projekty</option>-->
<!--                  <option v-for="project in projects" :key="project.id" :value="project.id">-->
<!--                    {{ project.label }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->

<!--              <div class="col-md-12 col-lg-6">-->
<!--                <label for="team-select">Wybierz zespół:</label>-->
<!--                <select id="team-select" class="form-select form-select-sm" @change="updateSelectedTeam"-->
<!--                        v-model="selectedTeamName" :disabled="!selectedProjectId || (teams && teams.length === 0)">-->
<!--                  <option value="" disabled>Wybierz zespół</option>-->
<!--                  <option v-for="team in teams" :key="team.name" :value="team.name">-->
<!--                    {{ team.name }}-->
<!--                  </option>-->
<!--                </select>-->
<!--              </div>-->

            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="warehouseGrid" id="warehouseGridId" height="500px"
                      :dataSource="warehouseDataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='true'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :contextMenuItems="contextMenuItems"
                      :groupSettings='groupOptions'
                      :rowSelected="onRowSelected"
                      :toolbarClick="clickToolbarHandler"
                      :contextMenuClick="clickContextMenuHandler"
                      :actionComplete="actionCompleteHandler"
                      @contextMenuOpen="onContextMenuOpen"
                      :keyPressed="onKeyDown">
              <e-columns>
                <e-column field="id" headerText="ID" :allowEditing="false" isPrimaryKey="true"
                          :visible="false"></e-column>
                <e-column field="article.id" headerText="ID artykułu" :allowEditing="false" :visible="false"></e-column>
                <e-column field="article.catalog_number" headerText="Nr artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article.model" headerText="Model artykułu" :allowEditing="false"
                          :filter="filterDefault"></e-column>
                <e-column field="article_warehouse.short_name" headerText="Magazyn" :allowEditing="false"
                          :filter="filterWarehouses"></e-column>
                <e-column field="quantity" headerText="Ilość" :alowEdit="false"></e-column>
                <e-column field="updated_at" headerText="Ostatnia aktualizacja" :allowEditing="false"
                          format="dd.MM.yyyy"
                          :filter="filterDefault"></e-column>
              </e-columns>

            </ejs-grid>
          </div>

          <ViewArticleWarehouseMovementDetails :selectedArticle="selectedArticle"></ViewArticleWarehouseMovementDetails>

        </div>
      </div>
    </div>


  </AppLayout>
</template>

<script>
import AppLayout from "../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  Group,
} from '@syncfusion/ej2-vue-grids';
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor} from '@syncfusion/ej2-data';
import {ComboBox, DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import {ref} from "vue";

import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import ViewArticleWarehouseMovementDetails from "./Articles/ViewArticleWarehouseMovementDetails.vue";

const {hasRole} = usePermission();


let dropInstanceFilterUnits, statusElem, statusObj;
export default {

  components: {
    ViewArticleWarehouseMovementDetails,
    AppLayout,
    MultiSelect,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, ContextMenu, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, Group]
  },

  props: {
    projects: Object,
    order_list_statuses: Object,
    units: Object,
    categories: Object,
    producers: Object,
    locations: Object,
    warehouses: Object,
  },

  beforeMount() {
    this.getWarehousesItemsForProject();
  },

  data() {

    return {
      teams: [],
      initialGrouping: true,
      warehouseDataManager: [],
      fetchedOrders: [],
      selectedProjectId: 0,
      selectedTeamName: null,
      addRequiredProjectItemsModalRef: ref(null),
      editArticleModalRef: ref(null),
      showEditArticleModal: false,
      selectedArticle: null,
      selectedRecordsToSendOrder: null,
      pageSettings: {pageSize: 50, pageCount: 5},
      filterOptions: {type: "Menu", operator: 'contains'},
      filterDefault: {operator: 'contains'},
      isRestoring: false,
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      contextMenuItems: this.getContextMenu(),
      groupOptions: {showGroupedColumn: true, columns: ['order_list.order_number']},

      currenciesParams: {
        create: () => {
          return document.createElement('input');
        },
        read: () => {
          return this.comboBoxObj.value;
        },
        destroy: () => {
          this.comboBoxObj.destroy();
        },
        write: (args) => {
          this.comboBoxObj = new ComboBox({
            dataSource: [
              {text: 'ZŁ', value: 'ZŁ'},
              {text: 'EUR', value: 'EUR'},
              {text: 'USD', value: 'USD'},
            ],
            fields: {text: "text", value: "value"},
          });
          this.comboBoxObj.appendTo(args.element);
        }
      },

      filterUnits: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterProducers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj producentów',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterSuppliers: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterWarehouses: {
        operator: 'contains',
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      orderItemStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.order_list_statuses,
            fields: {value: 'id', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never',
          });
          statusObj.appendTo(statusElem);
        }
      },

      orderItemWarehousesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.warehouses,
            fields: {value: 'id', text: 'short_name'},
            enabled: true,
            placeholder: 'Wybierz magazyn',
            floatLabelType: 'Never',
            change: async (event) => {
              statusObj.value = await this.onChangeWarehouse(event)
            }
          });
          statusObj.appendTo(statusElem);
        }
      },

      inventoryStatusesParams: {
        create: () => {
          statusElem = document.createElement('input');
          return statusElem;
        },
        read: () => {
          return statusObj.text;
        },
        destroy: () => {
          statusObj.destroy();
        },
        write: () => {
          statusObj = new DropDownList({
            dataSource: this.inventory_statuses,
            fields: {value: 'name', text: 'name'},
            enabled: true,
            placeholder: 'Wybierz status',
            floatLabelType: 'Never'
          });
          statusObj.appendTo(statusElem);
        }
      },
    }
  },

  methods: {
    getEditSettings() {
      return {
        allowEditing: false,
        allowAdding: true,
        allowDeleting: false,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        {text: "Resetuj filtry", prefixIcon: 'e-reset', id: 'reset_filters'},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    clickToolbarHandler: async function (args) {
      /**
       * Akcja przy kliknięciu resetowania filtrów
       */
      if (args.item.id === 'reset_filters') {
        this.$refs.warehouseGrid.clearFiltering();
      }
    },

    getContextMenu() {
      return [
        {text: 'Dyspozycja magazynowa', target: '.e-content', id: 'warehouse_disposition'},
        {separator: true},
        {text: 'Generuj PDF - PW', target: '.e-content', id: 'pdf-pw'},
        {text: 'Generuj PDF - MM', target: '.e-content', id: 'pdf-mm'},
        {text: 'Generuj PDF - RW', target: '.e-content', id: 'pdf-rw'},
        {text: 'Generuj PDF - WZ', target: '.e-content', id: 'pdf-wz'},
        // {separator: true},
      ];
    },

    onContextMenuOpen(args) {
    },

    clickContextMenuHandler(args) {

    },

    onRowSelected(args) {
      this.selectedArticle = args.data ?? {};
    },


    getWarehousesItemsForProject() {

      this.warehouseDataManager = new DataManager({
        url: route('warehouse.getArticleList'),
        adaptor: new WebMethodAdaptor(),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ],
      });
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        // aktualizuj
        let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
        let buttons = toolbar.querySelectorAll('.e-toolbar-item');
        let updateButton = buttons[2].querySelector('button');
        if (updateButton) {
          updateButton.click();

        }
      }
    },

    actionCompleteHandler(args) {
      if (args.requestType === "batchsave") {  // Tylko dla operacji batchSave (zapis)
        if (args.changes && args.changes.changedRecords) {
          // Sprawdzamy czy `args.batchChanges` istnieje i czy są zmienione rekordy
          this.successMsg("Dane zostały pomyślnie zapisane!");

        } else if (args.error) {
          // Jeśli wystąpił błąd, wywołaj `errorMsg` z wiadomością błędu
          this.errorMsg("Wystąpił błąd podczas zapisu danych: " + args.error);
        }
      }
    },

    async onChangeWarehouse(event) {
      // Czy powraca co poprzedniej wartości,
      // Jeśli tak to nie pokazuj Toasta
      if (this.isRestoring) {
        this.isRestoring = false;
        return;
      }

      const result = await Toast.fire({
        toast: false,
        position: 'center',
        icon: 'question',
        title: 'Zmiana magazynu',
        html: 'Czy na pewno chcesz zmienić magazyn dla tego artykułu?',
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: false,
        showConfirmButton: true,
        confirmButtonText: 'Tak',
        confirmButtonColor: 'success',
        showCancelButton: true,
        cancelButtonText: 'Nie',
      });

      if (result.isConfirmed) {
        return event.itemData;
      } else {
        this.isRestoring = true;
        return event.previousItemData;
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    formatDeliverers(data) {
      // Paruj JSON i formatuj jako ciąg znaków
      try {
        const names = JSON.parse(data.deliverers_short_names);
        return names.join(', ');
      } catch (e) {
        // Jeśli dane nie są poprawnym JSON-em, zwróć je bez zmian
        return data.deliverers_short_names;
      }
    },

    isAdmin() {
      return hasRole('ADMIN');
    },

  }
}
</script>

<style scoped>
.not-editable {
  color: orangered;
}
</style>