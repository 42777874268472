<template>
  <div v-if="selectedArticle">

    <!-- Modal -->
    <div class="modal fade" id="historyModal" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Historia {{ selectedArticle?.catalog_number }}</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Nr katalogowy</th>
                  <th>Model</th>
                  <th>J.m.</th>
                  <th>Nazwa/Opis</th>
                  <th>Utworzył</th>
                  <th>Producent</th>
                  <th>Kategoria</th>
                  <th>Cena Netto zł</th>
                  <th>Cena brutto zł</th>
                  <th>Cena Netto eur</th>
                  <th>Cena Brutto eur</th>
                  <th>Zdjęcie</th>
                  <th>Lokalizacja (magazyn)</th>
                  <th>Ilość/Stany magazynowe</th>
                  <th>Przychód</th>
                  <th>Rozchód</th>
                  <th>Wartość (netto)</th>
                  <th>Wartość (brutto)</th>
                  <th>Dostawca</th>
                  <th>Data aktualizacji</th>
                  <th>Zaktualizował</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(article, index) in articleHistory" :key="article.id">
                  <td>{{ article.article_id }}</td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'catalog_number') }">
                    {{ article.catalog_number }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'model') }">{{
                      article.model
                    }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'article_unit.name') }">
                    {{ article.article_unit?.name }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'description') }">{{
                      article.description
                    }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'created_by_name') }">
                    {{ article.created_by_name }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'producer.name') }">
                    {{ article.producer?.name }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'customer_category.name') }">
                    {{ article.customer_category?.name }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'price_netto_zl') }">
                    {{ article.price_netto_zl }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'price_brutto_zl') }">
                    {{ article.price_brutto_zl }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'price_netto_eur') }">
                    {{ article.price_netto_eur }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'price_brutto_eur') }">
                    {{ article.price_brutto_eur }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'photo_base64') }">
                    <span v-for="photo in article.photos">
                      <img :src="'data:image/png;base64,' + photo.photo_base64" alt="photo"/>
                    </span>
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'location.name') }">
                    {{ article.location?.name }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'warehouse.name') }">
                    {{ article.warehouse?.name }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'stock_quantity') }">{{
                      article.stock_quantity
                    }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'income') }">{{
                      article.income
                    }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'outflow') }">{{
                      article.outflow
                    }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'value_net') }">
                    {{ article.value_net }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'value_gross') }">{{
                      article.value_gross
                    }}
                  </td>
                  <td :class="{ 'border-2 border-danger text-danger': compareWithPrevious(index, 'suppliers') }">
                    {{ article.suppliers }}
                  </td>
                  <td>{{ article.updated_at }}
                  </td>
                  <td> {{ article.updated_by?.first_name }} {{ article.updated_by?.last_name }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">zamknij</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {Modal} from 'bootstrap';

export default {
  props: {
    selectedArticle: Object,
  },

  data() {
    return {
      modal: null,
      articleHistory: [], // Dane historii
    };
  },

  watch: {
    selectedArticle: {
      immediate: true,
      handler(newArticle) {
        if (newArticle) {
          this.fetchArticleHistory(newArticle.id);
        }
      }
    }
  },

  mounted() {
    const modalElement = document.getElementById('historyModal');
    // Nasłuchiwanie na zdarzenie zamknięcia modalu
    modalElement.addEventListener('hide.bs.modal', this.onModalClose);
  },
  beforeUnmount() {
    const modalElement = document.getElementById('historyModal');
    // Usuń nasłuchiwanie, gdy komponent jest odmontowywany
    modalElement.removeEventListener('hide.bs.modal', this.onModalClose);
  },

  methods: {
    fetchArticleHistory(articleId) {
      axios.get(route('articles.getHistory', {article: articleId})).then(response => {
        this.articleHistory = response.data.history;

        this.modal = new Modal(document.getElementById('historyModal'));
        this.modal.show();

      }).catch(error => {
        console.log(error);
      });
    },

    compareWithPrevious(index, field) {
      if (index === this.articleHistory.length - 1) {
        return false;
      }

      const fields = field.split('.');
      let currentValue = this.articleHistory[index];
      let previousValue = this.articleHistory[index + 1];

      fields.forEach((f) => {
        currentValue = currentValue?.[f];
        previousValue = previousValue?.[f];
      });

      return currentValue !== previousValue;
    },

    onModalClose() {
      // Emitowanie zdarzenia zamknięcia modalu
      this.$emit('close');
    }

  }
};
</script>