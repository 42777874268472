<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="changeDeliveryDateModal" ref="modalEle" tabindex="-1" role="dialog"
         aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-full-width" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Zmień datę dostawy</h5>

            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="error" class="alert alert-danger" role="alert">
              {{ error }}
            </div>

            <div class="col-md-12 col-lg-3">
              <label for="project-select">Wybierz datę dostawy</label>
              <ejs-datepicker
                  :weekNumber="true"
                  format="dd.MM.yyyy"
                  :placeholder="'Wybierz datę'"
                  :value="newSelectedDate"
                  @change="onDateChange"
              ></ejs-datepicker>
            </div>

            <div class="table-responsive mt-5">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th>Nr poz.</th>
                  <th> Podzespół nr rysunku</th>
                  <th>Nr katalogowy</th>
                  <th>Model</th>
                  <th>Nazwa/Opis</th>
                  <th>Ilość wymagana</th>
                  <th>Ilość magazynowa</th>
                  <th>Status</th>
                  <th>Status Mag</th>
                  <th>Data dostawy</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="record in selectedRecords">
                  <td>{{ record.id }}</td>
                  <td>{{ record.subteam_drawing_no }}</td>
                  <td>{{ record.article.catalog_number }}</td>
                  <td>{{ record.article.model }}</td>
                  <td>{{ record.article.description }}</td>
                  <td>{{ record.quantity_required }}</td>
                  <td>{{ record.article.stock_quantity }}</td>
                  <td>{{ record.status }}</td>
                  <td>{{ record.inventory_status }}</td>
                  <td>{{ record.delivery_date }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              <i class="bi bi-x-square me-2"></i>Zamknij
            </button>

            <form @submit.prevent="submitForm">
              <button type="submit" class="btn btn-primary">
                <i class="bi bi-save me-2"></i>Zapisz
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {Modal} from "bootstrap";
import {DatePickerComponent} from "@syncfusion/ej2-vue-calendars";

let dropInstanceFilterUnits, delivererElem, multiSelectObj;

export default {
  components: {
    "ejs-datepicker": DatePickerComponent,
  },

  props: {
    selectedRecords: Object,
    statuses: Object
  },

  data() {

    return {
      newSelectedDate: null,
      error: null,
    }
  },

  mounted() {
    this.modalObj = new Modal(this.$refs.modalEle);
  },

  methods: {
    onDateChange(args) {
      this.newSelectedDate = args.value;
    },

    submitForm() {
      axios.post(route('requiredProjectItems.change-delivery-date', {
            records: this.selectedRecords,
            newDeliveryDate: this.newSelectedDate
          }
      )).then(() => {
        this.$emit('added-success', 'Zmieniono datę zwolnienia poprawnie');
        this.$emit('updated-article');
        this.hide();
      }).catch(error => {
        this.error = error.response.data
        console.log('error', error);
      });
    },

    show() {
      if (this.modalObj) {
        this.modalObj.show();
      }
    },
    hide() {
      if (this.modalObj) {
        this.modalObj.hide();
      }
    },
  }
}

</script>

<style scoped>

</style>