<template>
  <div id="preview mt-5" style="margin-top:50px;" v-if="selectedArticle">
    <h5>Historia magazynowa artykułu {{ selectedArticle.article.catalog_number }} - {{
        selectedArticle.article.model
      }}</h5>

    <div class="mt-4 table-responsive">
      <table class="table table-striped" v-if="articleHistory">
        <thead>
        <tr>
          <th>Numer Dokumentu</th>
          <th>Magazyn</th>
          <th>Ilość</th>
          <th>Komentarz</th>
          <th>Utworzony przez</th>
          <th>Data Utworzenia</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="article in articleHistory">
          <td>{{ article.document_number }}</td>
          <td>{{ article.article_warehouse?.short_name }}</td>
          <td>{{ article.quantity }}</td>
          <td>{{ article.comment }}</td>
          <td>{{ article.created_by_name }}</td>
          <td>{{ dateFormatter(article.created_at) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</template>

<script>

import moment from "moment/moment.js";

export default {
  components: {},
  props: {
    selectedArticle: Object
  },

  data() {
    return {
      articleHistory: null,
    }
  },

  watch: {
    selectedArticle: {
      immediate: true,
      handler(newArticle) {
        if (newArticle) {
          this.getHistoryData(newArticle.id);
        }
      }
    }
  },

  methods: {
    getHistoryData() {
      axios.get(route('warehouse-movements.get.article-warehouses-movement-details',
          {article: this.selectedArticle.article})
      ).then(response => {
        this.articleHistory = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {

      });
    },

    dateFormatter(date) {
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },
  }
}
</script>

<style scoped>

</style>